import React, { useEffect, useState } from 'react';
import './order-details-item.scss';
import { OrderLineDetails } from '@services/oms-service/oms-service';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { SecondaryButton } from '@common/index';
import { INSTALL_LINES_ID, LinkTargetValues } from '@constants';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NVC_GREEN_STATUSES,
    NVC_ORDER_STATUS_COLORS,
    NVC_ORDER_STATUS_MAP,
    NVC_RED_STATUSES,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@views/order-details-view/nvc-status-map';
import { useAnalytics } from '@/hooks/use-analytics';

export interface OrderDetailsItemProps {
    itemData: OrderLineDetails;
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    returnDisabled?: boolean;
    return?: boolean;
    combinedStatus?: boolean;
}

const installLinesIcon = './icons/installation-item--icon.svg';

const OrderDetailsItem = (props: OrderDetailsItemProps) => {
    const [fireAnalytics] = useAnalytics();
    const formattedProductName: string = props.itemData.item.itemShortDesc
        .toLowerCase()
        .split(' ')
        .join('-')
        .replace(/[^a-z0-9-]/g, '')
        .replace(/-{2,}/g, '-');

    const partNumber = props.itemData.item.itemId;
    const deliveryMethod: string = props.itemData.deliveryMethod;
    const orderStatus: string = props.itemData.status.toLowerCase();
    const [orderDisplayStatus, setorderDisplayStatus] = useState(
        props.itemData.status
    );
    const [orderNonReturnable, setorderNonReturnable] = useState(false);
    const [isInstallationItem, setIsInstallationItem] =
        useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string>();

    const productPageUrlTemplate = props.orderDetailsContent?.productPageUrl;

    const accessoriesUrl = props.orderDetailsContent?.accessoriesUrl;

    const createProductPageUrl = (
        template?: string,
        values?: { productName: any; partNumber: any }
    ) => {
        return template
            ?.replace(/:productName/g, values.productName)
            .replace(/:partNumber/g, values.partNumber);
    };

    const productPageUrl = props.itemData.item.itemShortDesc
        ? createProductPageUrl(productPageUrlTemplate, {
              productName: formattedProductName,
              partNumber: partNumber,
          })
        : accessoriesUrl;

    const createImageUrl = () => {
        let imageUrl = '';
        if (props.itemData.item?.imageID) {
            const partImageId = props.itemData.item.imageID;
            if (props.itemData.item?.itemSubType === 'FPP')
                imageUrl =
                    props.orderDetailsContent?.performancePartsImageUrl?.replace(
                        '[partNumber]',
                        partImageId
                    );
            else
                imageUrl =
                    props.orderDetailsContent?.accessoriesImageUrl?.replace(
                        '[partNumber]',
                        partImageId
                    );
        } else {
            imageUrl = props.orderDetailsContent?.accessoriesImageUrl?.replace(
                '[partNumber]',
                'No_Image_Ford.jpg'
            );
        }
        setImageSrc(imageUrl);
    };

    const chooseStatusBackgroundColor = (status: string): string => {
        if (props.returnDisabled) {
            if (orderNonReturnable) {
                return NVC_ORDER_STATUS_COLORS.red;
            }
            return NVC_ORDER_STATUS_COLORS.gray;
        }
        if (NVC_GREEN_STATUSES.includes(status)) {
            return NVC_ORDER_STATUS_COLORS.green;
        } else if (NVC_RED_STATUSES.includes(status)) {
            return NVC_ORDER_STATUS_COLORS.red;
        }
        return NVC_ORDER_STATUS_COLORS.gray;
    };

    const generateImageSrc = (): void => {
        if (props.itemData.item.itemId === INSTALL_LINES_ID) {
            setIsInstallationItem(true);
            setImageSrc(installLinesIcon);
        } else {
            createImageUrl();
        }
    };

    const determineDisplayStatus = (status: string) => {
        const deliveryMethodTransformed: string =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                if (statusOption.options.includes(status.toLowerCase())) {
                    setorderDisplayStatus(
                        props.orderStatusContent[statusOption.name]
                    );
                }
            }
        );

        if (props.returnDisabled) {
            let returnDisplayStatus = '';
            NVC_ORDER_STATUS_MAP['returnOrder'].forEach(
                (statusOption: OrderStatusOption) => {
                    if (statusOption.options.includes(status.toLowerCase())) {
                        returnDisplayStatus =
                            props.orderStatusContent[statusOption.name];
                    }
                }
            );
            if (!returnDisplayStatus) {
                setorderDisplayStatus(props.orderDetailsContent?.nonReturnable);
                setorderNonReturnable(true);
            }
        }
    };

    const generateNonInstallItemPriceText = (): string => {
        const quantity = parseFloat(props.itemData.originalOrderedQty);
        const descriptor =
            quantity > 1
                ? props.orderDetailsContent?.multipleQuantityPriceDescriptor
                : '';
        const priceText = `${props.itemData.lineOverallTotals.unitPrice} ${descriptor}`;
        return priceText.trim();
    };

    useEffect(() => {
        generateImageSrc();
        determineDisplayStatus(orderStatus);
    }, []);

    return (
        <div
            className="order-details-item__container"
            data-testid="order-details-item__container"
        >
            {props.return ? (
                <>
                    {props.returnDisabled && (
                        <div
                            className={`item__status`}
                            data-testid="item-status"
                            style={{
                                backgroundColor:
                                    chooseStatusBackgroundColor(orderStatus),
                            }}
                        >
                            {!isInstallationItem && <p>{orderDisplayStatus}</p>}
                        </div>
                    )}
                    <div
                        className={
                            props.returnDisabled
                                ? 'return-disabled-grayscale'
                                : 'return-items'
                        }
                    >
                        {imageSrc && (
                            <>
                                {isInstallationItem ? (
                                    <img
                                        src={imageSrc}
                                        className="item__image install-icon-sizing"
                                        alt="item image"
                                        data-testid="item-image"
                                    />
                                ) : (
                                    <a
                                        href={productPageUrl}
                                        target={LinkTargetValues.BLANK}
                                        className="product-page-url"
                                    >
                                        <img
                                            src={imageSrc}
                                            className="item__image"
                                            alt="item image"
                                            data-testid="item-image"
                                        />
                                    </a>
                                )}
                            </>
                        )}

                        <div className="item__info-container">
                            {isInstallationItem ? (
                                <p
                                    className="item__title"
                                    data-testid="item-title"
                                >
                                    {props.itemData.item.itemShortDesc}
                                </p>
                            ) : (
                                <a
                                    className="item__title"
                                    data-testid="item-title"
                                    href={productPageUrl}
                                    target={LinkTargetValues.BLANK}
                                >
                                    {props.itemData.item.itemShortDesc}
                                </a>
                            )}
                            <p className="item__id" data-testid="item-id">
                                {props.itemData.item.itemId}
                            </p>
                            <p className="item__price" data-testid="item-price">
                                $
                                {isInstallationItem
                                    ? props.itemData.lineOverallTotals
                                          .lineTotalWithoutTax
                                    : generateNonInstallItemPriceText()}
                            </p>

                            {!isInstallationItem && (
                                <p
                                    className="item__quantity"
                                    data-testid="item-quantity"
                                >
                                    {props.orderDetailsContent?.quantity}{' '}
                                    {props.returnDisabled
                                        ? parseFloat(
                                              props.itemData.originalOrderedQty
                                          )
                                        : '1'}
                                </p>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={`item__status`}
                        data-testid="item-status"
                        style={{
                            backgroundColor:
                                chooseStatusBackgroundColor(orderStatus),
                        }}
                    >
                        {!isInstallationItem && props.combinedStatus && (
                            <p>{orderDisplayStatus}</p>
                        )}
                    </div>
                    {imageSrc && (
                        <>
                            {' '}
                            {isInstallationItem ? (
                                <img
                                    src={imageSrc}
                                    className="item__image install-icon-sizing"
                                    alt="item image"
                                    data-testid="item-image"
                                />
                            ) : (
                                <a
                                    href={productPageUrl}
                                    target={LinkTargetValues.BLANK}
                                >
                                    <img
                                        src={imageSrc}
                                        className="item__image"
                                        alt="item image"
                                        data-testid="item-image"
                                    />
                                </a>
                            )}
                        </>
                    )}
                    <div className="item__info-container">
                        {isInstallationItem ? (
                            <p className="item__title" data-testid="item-title">
                                {props.itemData.item.itemShortDesc}
                            </p>
                        ) : (
                            <a
                                className="item__title"
                                data-testid="item-title"
                                href={productPageUrl}
                                target={LinkTargetValues.BLANK}
                            >
                                {props.itemData.item.itemShortDesc}
                            </a>
                        )}

                        <p className="item__id" data-testid="item-id">
                            {props.itemData.item.itemId}
                        </p>
                        <p className="item__price" data-testid="item-price">
                            $
                            {isInstallationItem
                                ? props.itemData.lineOverallTotals
                                      .lineTotalWithoutTax
                                : generateNonInstallItemPriceText()}
                        </p>
                        {!isInstallationItem && (
                            <p
                                className="item__quantity"
                                data-testid="item-quantity"
                            >
                                {props.orderDetailsContent?.quantity}{' '}
                                {parseFloat(props.itemData.originalOrderedQty)}
                            </p>
                        )}
                    </div>
                    <div className="item__buttons-container">
                        {!isInstallationItem && (
                            <SecondaryButton
                                className="buy-again-button"
                                data-testid="buy-again-button"
                                linkTarget={LinkTargetValues.BLANK}
                                link={productPageUrl}
                                internal={false}
                                onClick={() => {
                                    fireAnalytics(
                                        'orderDetailsBuyAgainOnclickEvent'
                                    );
                                }}
                            >
                                {props.orderDetailsContent?.buyAgain}
                            </SecondaryButton>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default OrderDetailsItem;
