import React, { useContext, useEffect, useState } from 'react';

import { useProfileData } from '@sections/account-portal/hooks/use-profile-data';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import UserCommunicationPreferenceService from '@services/communication-preference-service/communication-preference-service';
import { useAnalytics } from '@hooks/use-analytics';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { CacheService } from '@services/cache-service/cache-service';
import ErrorPage from '@sections/profile-error/error-page';
import seoService from '@services/seo-service/seo-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import EmailCommunicationPreferencesSection from '@sections/email-communication-preference/email-communication-preferences';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { findPathByAlias } from '@routes/routesList';
import { useCommunicationPreferencesContent } from './hooks/use-communication-preference-content';
import './communication-preferences-view.scss';
import { IMG_COUNTRIES, NA_COUNTRIES } from '@constants';
import EmailCommunicationPreferencesFormSection from '@/components/sections/email-communication-preference/email-communication-preferences-form';
import { LinkButton } from '@/components/common/modal/link-button/link-button';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { useNotificationBannerContent } from '@sections/notification-banner/hooks/use-notification-banner-content';
import { SearchBar } from '@/components/sections/search-bar/search-bar';
import ReturnButton from '@sections/return-button/return-button';
import { useConnectedStatusContext } from '@contexts/connectedStatusContext';

type ModalType = 'success' | 'confirmation' | '';

const className = 'communication-preference';

const CommunicationPreferencesView = () => {
    const { setNotificationContext } = useNotificationContext();
    useAnalytics(['ownerAccountCommPrefs']);
    const [fireAnalytics] = useAnalytics();
    const [showErrorPage, handleShowErrorPage] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const user = useProfileData();
    const communicationPreferenceLabels = useCommunicationPreferencesContent();
    const returnButtonContent = useReturnButtonContent();
    const scrollUtil = new ScrollUtil();
    const [values, setValues] = useState([
        {
            name: 'email-preference',
            value: 'email-preference',
            isChecked: user?.profile?.sendMarketingEmails === 1,
            displayName: communicationPreferenceLabels?.emailOptionHeader,
        },
    ]);

    const service = new UserCommunicationPreferenceService();

    const [modalState, setModalState] = useState<boolean>(false);
    const [marketingEmailSubscribed, setMarketingEmailSubscribed] =
        useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalType>('');

    const [loading, setIsLoading] = useState(false);
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    const appConfig = new AppConfigurationService();
    const getAppConfiguration = appConfig.getAppConfiguration();
    const isNa = NA_COUNTRIES.includes(getAppConfiguration.countryCode);
    const isImg = IMG_COUNTRIES.includes(getAppConfiguration.countryCode);
    const authenticationService = new AuthenticationService();
    const { getConnectedStatus } = useConnectedStatusContext();
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const notificationBannerContent = useNotificationBannerContent();

    authenticationService.updateState(
        findPathByAlias('CommunicationPreferenceView')
    );
    const [bannerCopy, setBannerCopy] = useState<string>('');

    seoService.set(
        {
            pageTitle: appInformationContent?.communicationPreferencesPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription:
                appInformationContent?.communicationPreferencesPageDescription,
        },
        serverContext
    );

    const cacheService = new CacheService();

    // handle user input change
    const handleChange = (updatedValues) => {
        setValues(updatedValues);
    };

    // handle communication preference save
    const handleSave = async () => {
        fireAnalytics('emailSaveOnclickEvent');
        const userPreference = values[0].isChecked;
        const isSuccessModal = userPreference ? 'success' : 'confirmation';
        setModalType(isSuccessModal);
        if (userPreference && isNa) {
            // invalidate cache after updating communication preference
            cacheService.evictProfileServiceCache();
            setIsLoading(true);

            const response = await service.updateNaCommunicationPreference(
                userPreference
            );
            if (response) {
                setIsLoading(false);
                setModalState(true);
            } else {
                setIsLoading(false);
                handleShowErrorPage(true);
            }
        } else if (userPreference && isImg) {
            cacheService.evictProfileServiceCache();
            setIsLoading(true);

            const response = await service.updateImgCommunicationPreference(
                userPreference
            );

            if (response) {
                setIsLoading(false);
                setModalState(true);
            } else {
                setIsLoading(false);
                handleShowErrorPage(true);
            }
        } else {
            setModalState(true);
        }
    };

    // handle modal close
    const handleClose = () => {
        const checkbox = document.getElementById('email-preference-0');

        setModalState(false);

        // set focus to checkbox when modal closes
        checkbox.focus();
    };

    const handleDisplayForm = (
        flag: boolean,
        bannerType: string,
        bannerText: string,
        marketingEmailSubscribed: boolean
    ) => {
        setIsEdit(flag);
        setNotificationStatus(
            bannerType === 'Success'
                ? NotificationType.Success
                : NotificationType.Error
        );
        setBannerCopy(
            bannerText === 'subscribe'
                ? notificationBannerContent?.notificationSubscribeSuccessText
                : bannerText === 'unsubscribe'
                ? notificationBannerContent?.notificationUnsubscribeSuccessText
                : bannerText === 'error'
                ? notificationBannerContent?.notificationFailureText
                : ''
        );
        setMarketingEmailSubscribed(marketingEmailSubscribed);
    };

    // handle unsubscribe user confirmation
    const handleConfirmationModalConfirmation = async () => {
        fireAnalytics('confirmUnsubscribeOnclickEvent');

        // invalidate cache after updating communication preference
        cacheService.evictProfileServiceCache();
        handleClose();
        setIsLoading(true);

        if (isNa) {
            const response = await service.updateNaCommunicationPreference(
                values[0].isChecked
            );
            if (response) {
                setIsLoading(false);
                setModalState(true);
                setModalType('success');
            } else {
                setIsLoading(false);
                handleShowErrorPage(true);
            }
        } else if (isImg) {
            const response = await service.updateImgCommunicationPreference(
                values[0].isChecked
            );
            if (response) {
                setIsLoading(false);
                setModalState(true);
                setModalType('success');
            } else {
                setIsLoading(false);
                handleShowErrorPage(true);
            }
        }
    };

    // method will call on changes in emailOptionHeader and sendMarketingEmails from profile service
    useEffect(() => {
        const updateValues: any[] = [...values];
        updateValues[0].displayName =
            communicationPreferenceLabels?.emailOptionHeader;

        //check for initial marketing email value
        updateValues[0].isChecked = user?.profile?.sendMarketingEmails === 1;
        if (user?.profile?.sendMarketingEmails === 1) {
            setMarketingEmailSubscribed(true);
        } else {
            setMarketingEmailSubscribed(false);
        }
        setValues(updateValues);
        scrollUtil.scrollPageToTop();
    }, [
        communicationPreferenceLabels?.emailOptionHeader,
        user?.profile?.sendMarketingEmails,
    ]);

    const isContentFragmentLoaded =
        returnButtonContent &&
        communicationPreferenceLabels &&
        Object.keys(communicationPreferenceLabels)?.length > 0;

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    useEffect(() => {
        if (user?.profile) {
            getConnectedStatus(user);
        }
    }, [user]);

    return (
        <>
            {user &&
            user.profile &&
            isContentFragmentLoaded &&
            !showErrorPage ? (
                <div
                    className={`limit-1440 ${className}${
                        communicationPreferenceLabels.editButton
                            ? '-container-edit'
                            : '-container'
                    }`}
                    data-testid="communication-preference-view"
                >
                    <ReturnButton returnButtonContent={returnButtonContent} />
                    {loading && !communicationPreferenceLabels.editButton && (
                        <ActivityIndicator
                            className={'fds-activity-indicator__center'}
                        />
                    )}
                    {!communicationPreferenceLabels.editButton && (
                        <EmailCommunicationPreferencesSection
                            modalState={modalState}
                            communicationPreferenceContent={
                                communicationPreferenceLabels
                            }
                            modalType={modalType}
                            handleClose={handleClose}
                            handleConfirmationModalConfirmation={
                                handleConfirmationModalConfirmation
                            }
                            className={className}
                            values={values}
                            handleSave={handleSave}
                            handleChange={handleChange}
                        />
                    )}
                    {communicationPreferenceLabels.editButton && (
                        <>
                            <div className="communication-preferences-form-container">
                                {notificationStatus && (
                                    <div
                                        className={`communication-preferences-view-notification-container`}
                                        data-testid={`communication-preferences-view-notification-container`}
                                    >
                                        <Notification
                                            status={notificationStatus}
                                            mainCopy={bannerCopy}
                                            hideBorder={true}
                                            hideAfterTimeout={true}
                                            onHideNotification={() => {
                                                setNotificationStatus(null);
                                            }}
                                        />
                                    </div>
                                )}
                                <h1 className="fmc-type--heading6 fds-color__text--primary account-view-section__title fmc-mt-4-xs fmc-pt-1-xs">
                                    {communicationPreferenceLabels.pageHeader}
                                </h1>

                                <div className="account-view-section">
                                    <h2 className="fmc-type--heading6 fds-color__text--primary account-view-section__subtitle fmc-mt-4-xs fmc-pt-1-xs">
                                        {
                                            communicationPreferenceLabels.preferencesSubHeader
                                        }
                                    </h2>

                                    <div className="edit-button-container">
                                        {!isEdit && (
                                            <LinkButton
                                                ariaControls={
                                                    'personal-information-form'
                                                }
                                                ariaExpanded={false}
                                                label={
                                                    communicationPreferenceLabels.editButton
                                                }
                                                ariaLabel={
                                                    communicationPreferenceLabels.editButton
                                                }
                                                dataTestId="user-info-edit-btn"
                                                class={`edit-link`}
                                                onClick={() => {
                                                    setIsEdit(true);
                                                }}
                                                id="edit-link"
                                                ariaHidden={false}
                                                tabIndex={-1}
                                            />
                                        )}
                                    </div>
                                </div>
                                {!isEdit || appConfig.brand === 'ford' ? (
                                    <hr
                                        className="hr-line mB0"
                                        aria-hidden={true}
                                    />
                                ) : null}
                                {isEdit ? (
                                    <EmailCommunicationPreferencesFormSection
                                        displayForm={handleDisplayForm}
                                        defaultSelection={
                                            marketingEmailSubscribed
                                        }
                                    />
                                ) : (
                                    <div className="email-communication-container">
                                        <div className="email-communication-label">
                                            {
                                                communicationPreferenceLabels.emailOptionHeader
                                            }
                                        </div>
                                        <div className="email-communication-status">
                                            {marketingEmailSubscribed
                                                ? communicationPreferenceLabels.yesSubscribe
                                                : communicationPreferenceLabels.noUnSubscribe}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr className="hr-line" aria-hidden={true} />
                        </>
                    )}
                    <SearchBar />
                </div>
            ) : showErrorPage ? (
                <ErrorPage />
            ) : (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};
export default CommunicationPreferencesView;
