import React, { Fragment, useState } from 'react';
import UserEmailChannelPreferenceService from '@services/email-channel-preference-service/email-channel-preference-service';
import HttpService from '@services/http-service/http-service';
import { CommunicationPreferenceLabels } from '@views/communication-preferences-view/hooks/use-communication-preference-content';
import { PrimaryButton } from '@common/index';
import { InputField } from '@common/form-fields/form-fields';
import Validator from '@utils/validator/validator';
import ErrorPage from '../profile-error/error-page';
import { ChangeEmailContent } from './hooks/use-change-email-content';
import './change-email.scss';

export interface Errors {
    existingEmailField: { status: boolean; message: string };
    newEmailField: { status: boolean; message: string };
    confirmEmailField: { status: boolean; message: string };
}

export const errors: Errors = {
    existingEmailField: {
        status: false,
        message: '',
    },
    newEmailField: {
        status: false,
        message: '',
    },
    confirmEmailField: {
        status: false,
        message: '',
    },
};

export interface ChangeEmailProps {
    consumerId: string;
    countryCode: string;
    communicationPreferenceLabels: CommunicationPreferenceLabels;
    changeEmailContent: ChangeEmailContent;
}

export const validationData = (changeEmailContent?: ChangeEmailContent) => {
    return {
        existingEmailField: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: changeEmailContent?.emailPatternRules,
            label: changeEmailContent?.existingEmailFieldLabel,
        },
        newEmailField: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: changeEmailContent?.emailPatternRules,
            label: changeEmailContent?.newEmailFieldLabel,
        },
    };
};

const ChangeEmailComponent = (props: ChangeEmailProps) => {
    const service = new UserEmailChannelPreferenceService(HttpService);
    const inputValidations = validationData(props.changeEmailContent);
    const [focus, setFocus] = useState({
        existingEmail: false,
        newEmail: false,
        confirmEmail: false,
    });
    const [existingEmail, setExistingEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [error, handleErrors] = useState<Errors>(errors);
    const [isThankYou, setIsThankYou] = useState(false);
    const [invalidExistingEmail, setInvalidExistingEmail] = useState(false);
    const [isApiErrorOnSubmit, setIsApiErrorOnSubmit] = useState(false);

    // email validation messages
    const validationMessages = {
        nonEmpty: props.changeEmailContent?.errorNonEmpty,
        pattern: props.changeEmailContent?.errorEmailInvalid,
        dontMatch: props.changeEmailContent?.errorEmailsDontMatch,
        existingEmailMatch: props.changeEmailContent?.errorInvalidExistingEmail,
    };

    const setFocusToField = (type: string, value: boolean) => {
        const focusedElements = { ...focus };
        focusedElements[type] = value;
        setFocus(focusedElements);
    };

    // method to validate New Email and Confirm Email
    const validateEmail = (fieldName: string, fieldValue: string) => {
        const fieldErrors = { ...error };
        const emailNotMatch = {
            status: true,
            message: validationMessages.dontMatch,
        };
        const emailMatch = {
            status: false,
            message: '',
        };
        if (fieldName === 'confirmEmailField') {
            fieldValue.toLowerCase() !== newEmail.toLowerCase()
                ? (fieldErrors[fieldName] = emailNotMatch)
                : (fieldErrors[fieldName] = emailMatch);
        }
        if (fieldName === 'newEmailField') {
            fieldValue.toLowerCase() === confirmEmail.toLowerCase() &&
                (fieldErrors['confirmEmailField'] = emailMatch);
            fieldValue.toLowerCase() !== confirmEmail.toLowerCase() &&
                (fieldErrors['confirmEmailField'] = emailNotMatch);
        }
        return fieldErrors;
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name: fieldName } = e.target;
        const { value: fieldValue } = e.target;
        if (fieldName === 'existingEmailField') {
            setExistingEmail(fieldValue);
            setInvalidExistingEmail(false);
        }
        if (fieldName === 'newEmailField') {
            setNewEmail(fieldValue);
        }
        if (fieldName === 'confirmEmailField') {
            setConfirmEmail(fieldValue);
        }
        const fieldErrors = validateEmail(fieldName, fieldValue);

        // validate field based on rules defined
        const dataAttributes = { ...e.currentTarget.dataset };
        const validationRules = dataAttributes.validationRules
            .split(',')
            .filter(Boolean);
        const pattern = dataAttributes.pattern;

        validationRules.length > 0 &&
            (fieldErrors[fieldName] = Validator.checkValidations(
                validationRules,
                fieldValue,
                'Email',
                pattern,
                validationMessages
            ));
        handleErrors(fieldErrors);
    };

    const handleSubmit = async () => {
        let response;

        try {
            response = await service.updateEmailAddress(
                props.consumerId,
                props.countryCode,
                existingEmail,
                newEmail
            );
            return response.data.responseStatus.status && setIsThankYou(true);
        } catch (e) {
            if (e.response.status === 404) setInvalidExistingEmail(true);
            else return setIsApiErrorOnSubmit(true);
        }
    };

    return (
        <div
            className={'change-email-container fmc-pb-6'}
            data-testid="change-email-container"
        >
            {!isThankYou && !isApiErrorOnSubmit ? (
                <Fragment>
                    <h1
                        className={
                            'fmc-type--heading5 fds-color__text--primary fds-align--center fds-p--b-3 fds-p--t-3'
                        }
                        data-testid={'change-email-title'}
                    >
                        {props.changeEmailContent?.title}
                    </h1>
                    <form
                        className="change-email-form"
                        data-testid={'change-email-form'}
                    >
                        <p
                            className={
                                'required-fields-text fmc-type--body1 fds-color__text--gray3 fmc-pt-8 fmc-pb-6'
                            }
                        >
                            {props.changeEmailContent?.requiredFieldsText}
                        </p>
                        {/* Existing Email field */}
                        <InputField
                            id={'existingEmailField'}
                            dataTestId="existingEmailField"
                            value={existingEmail}
                            handleChange={handleChange}
                            name={'existingEmailField'}
                            className={'change-email-inputs'}
                            label={
                                props.changeEmailContent
                                    ?.existingEmailFieldLabel
                            }
                            aria-label={
                                props.changeEmailContent?.existingEmailAriaLabel
                            }
                            showLabel={focus.existingEmail}
                            onFocus={() =>
                                setFocusToField('existingEmail', true)
                            }
                            onBlur={() =>
                                setFocusToField('existingEmail', false)
                            }
                            validationRules={
                                inputValidations.existingEmailField
                                    .validationRules
                            }
                            validationPattern={
                                inputValidations.existingEmailField
                                    .validationPattern
                            }
                            error={
                                invalidExistingEmail
                                    ? {
                                          status: true,
                                          message:
                                              props.changeEmailContent
                                                  .errorInvalidExistingEmail,
                                      }
                                    : error.existingEmailField
                            }
                            required
                            placeholder={
                                props.changeEmailContent
                                    ?.existingEmailFieldLabel
                            }
                        />
                        {/* New Email field */}
                        <InputField
                            id={'newEmailField'}
                            dataTestId="newEmailField"
                            value={newEmail}
                            handleChange={handleChange}
                            name={'newEmailField'}
                            className={'change-email-inputs'}
                            label={props.changeEmailContent?.newEmailFieldLabel}
                            aria-label={
                                props.changeEmailContent?.newEmailAriaLabel
                            }
                            showLabel={focus.newEmail}
                            onFocus={() => setFocusToField('newEmail', true)}
                            onBlur={() => setFocusToField('newEmail', false)}
                            validationRules={
                                inputValidations.newEmailField.validationRules
                            }
                            validationPattern={
                                inputValidations.newEmailField.validationPattern
                            }
                            error={error.newEmailField}
                            required
                            placeholder={
                                props.changeEmailContent?.newEmailFieldLabel
                            }
                        />
                        {/* Confirm Email field */}
                        <InputField
                            id={'confirmEmailField'}
                            dataTestId={'confirmEmailField'}
                            value={confirmEmail}
                            handleChange={handleChange}
                            name={'confirmEmailField'}
                            className={'change-email-inputs'}
                            label={
                                props.changeEmailContent?.confirmEmailFieldLabel
                            }
                            aria-label={
                                props.changeEmailContent?.confirmEmailAriaLabel
                            }
                            showLabel={focus.confirmEmail}
                            onFocus={() =>
                                setFocusToField('confirmEmail', true)
                            }
                            onBlur={() =>
                                setFocusToField('confirmEmail', false)
                            }
                            error={confirmEmail && error.confirmEmailField}
                            required
                            placeholder={
                                props.changeEmailContent?.confirmEmailFieldLabel
                            }
                        />
                        <div
                            className={
                                'update-email-button-container fmc-pt-6 fmc-pb-9'
                            }
                        >
                            <PrimaryButton
                                testId={'change-email-submit-button'}
                                role={'button'}
                                aria-label={'Submit Updated Email Address'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                disabled={
                                    error.existingEmailField.status ||
                                    error.newEmailField.status ||
                                    error.confirmEmailField.status ||
                                    (existingEmail && !existingEmail.length) ||
                                    (newEmail && !newEmail.length) ||
                                    (confirmEmail && !confirmEmail.length) ||
                                    !existingEmail ||
                                    !newEmail ||
                                    !confirmEmail ||
                                    newEmail !== confirmEmail
                                }
                            >
                                {props.changeEmailContent?.submitButton}
                            </PrimaryButton>
                        </div>
                    </form>
                </Fragment>
            ) : !isApiErrorOnSubmit ? (
                // If Update Email is successful, show Thank You page
                <Fragment>
                    <div className={'thank-you-container'}>
                        <div>
                            <h1
                                className={
                                    'thank-you-title fmc-type--heading5 fds-color__text--primary fds-p--b-3 fds-p--t-3'
                                }
                                data-testid={'thank-you-title'}
                            >
                                {props.changeEmailContent?.thankYouHeader}
                            </h1>

                            <img
                                alt={
                                    props.changeEmailContent
                                        ?.thankYouSuccessIconAriaLabel
                                }
                                aria-label={
                                    props.changeEmailContent
                                        ?.thankYouSuccessIconAriaLabel
                                }
                                className={'success-icon'}
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    props.changeEmailContent
                                        ?.thankYouSuccessIcon
                                }
                            />

                            <p
                                className={
                                    'thank-you-description required-fields-text fmc-type--body1 fds-color__text--gray3 fmc-pt-6 fmc-pb-6'
                                }
                            >
                                {props.changeEmailContent?.thankYouDescription}
                            </p>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <ErrorPage
                    title={
                        props.communicationPreferenceLabels
                            .apiErrorMessageForECPTitle
                    }
                    description={
                        props.communicationPreferenceLabels
                            .apiErrorMessageForECPDescription
                    }
                />
            )}
        </div>
    );
};

export default ChangeEmailComponent;
