import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    EU_COUNTRIES,
    NA_COUNTRIES,
    PREFERRED_DEALER_STORAGE_KEY,
} from '@constants';
import { VehicleAttributes } from '@models/vehicle-attributes';
import ProfileService from '@services/profile-service/profile-service';
import { AddVehicle } from '../../../add-vehicle/add-vehicle';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { VehicleCarousel } from '../vehicle-carousel/vehicle-carousel';
import {
    useVehicleCarouselContent,
    VehicleCarouselContent,
} from './hooks/use-vehicle-carousel-content';
import { Greeting } from '../greeting/greeting';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { CacheService } from '@services/cache-service/cache-service';
import { MastheadContentProps } from '../../hooks/use-masthead-content';
import { MemberId } from '../../../member-id/member-id';
import {
    DynamicMastheadGreetingStyle,
    DynamicMastheadProperties,
} from './hooks/use-dynamic-masthead-content';
import { AddVehicleContent } from '../../../add-vehicle/hooks/use-add-vehicle';

import './vehicle-tabs.scss';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import GCCTService, {
    CustomerBooking,
} from '@services/gcct-service/gcct-service';
import HttpService from '@services/http-service/http-service';
import { useWindowSize } from '@hooks/use-window-size';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { useNotificationBannerContent } from '@sections/notification-banner/hooks/use-notification-banner-content';
import PersonIconLogo from '@sections/account-portal/components/vehicle-tabs/person-icon-logo/person-icon-logo';
import InitialsLogo from '@sections/account-portal/components/vehicle-tabs/initials-logo/initials-logo';
import FordPassRewards from '@sections/ford-pass-rewards/ford-pass-rewards';
import {
    FordPassRewardsContent,
    useFordPassRewardsContent,
} from '@sections/ford-pass-rewards/hooks/use-ford-pass-rewards-content';
import { useLoyaltyPointsDataForTile } from '@sections/account-portal/hooks/use-loyalty-points-data-for-tile';
import { PointsInfo } from '@services/loyalty-points-service/loyalty-points-service.interface';
import ActiveOrdersCarousel, {
    AccessoriesCarouselItem,
} from '@sections/active-orders-carousel/active-orders-carousel';
import { OmsOrderHistoryResponse } from '@services/oms-service/oms-service';

interface Props {
    addVehicleContent: AddVehicleContent;
    redirectToDashboard?: boolean;
    onTabClick?: any;
    vehiclesData?: VehicleAttributes[];
    setVehiclesData: Dispatch<SetStateAction<VehicleAttributes[]>>;
    isNewVehicleAdded?: boolean;
    vehiclesCount?: (count: number) => void;
    isMobile?: boolean;
    dynamicMastheadProperties: DynamicMastheadProperties;
    setDynamicMastheadProperties: Dispatch<
        SetStateAction<DynamicMastheadProperties>
    >;
    selectedDynamicMasthead: DynamicMastheadGreetingStyle;
    setSelectedDynamicMasthead: Dispatch<
        SetStateAction<DynamicMastheadGreetingStyle>
    >;
    openAddVehicleModal: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    currentVehiclesCount: number;
    setCurrentVehiclesCount: Dispatch<SetStateAction<number>>;
    vehiclesOnOrder: OrderedVehiclesItem[];
    deletedVehicleState: boolean;
    setDeletedVehicleState: Dispatch<SetStateAction<boolean>>;
    fromConnectedServices: boolean;
    setIsNewVehicleAdded: Dispatch<SetStateAction<boolean>>;
    fromAddVehicleParameter: boolean;
    deliveredVehicles: OrderedVehiclesItem[];
    vehicleCarouselContent: VehicleCarouselContent;
    mastheadContent: MastheadContentProps;
    activeOrders: AccessoriesCarouselItem[];
    orderHistoryData?: OmsOrderHistoryResponse;
}

export const VehicleTabs = (props: Props) => {
    const [memberId, setMemberId] = useState<any>(null);
    const { setNotificationContext } = useNotificationContext();
    const notificationBannerContent = useNotificationBannerContent();
    const screenSize = useWindowSize();
    const isMobileReimagine: boolean = screenSize.width < 992;
    const isTablet: boolean =
        screenSize.width >= 768 && screenSize.width <= 1200;
    const vehicleContentFragments = useVehicleCarouselContent();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [displayedCard, setDisplayedCard] = useState<number>(1);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [customerBookings, setCustomerBookings] =
        useState<CustomerBooking[]>(null);
    const vehiclesOnOrderSortedByPurchaseDate: OrderedVehiclesItem[] =
        props.vehiclesOnOrder &&
        props.vehiclesOnOrder?.sort((order1, order2) =>
            order2.purchaseDate?.localeCompare(order1.purchaseDate)
        );
    const [activeVehiclesOnOrder, setActiveVehiclesOnOrder] = useState<
        OrderedVehiclesItem[]
    >(props.vehiclesOnOrder);
    const profileService = new ProfileService();
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const isFord = appConfig.brand === 'ford';
    const httpService = HttpService;
    const gcctService = new GCCTService(httpService);
    const fordPassRewardsContent: FordPassRewardsContent =
        useFordPassRewardsContent();
    const isNa: boolean = NA_COUNTRIES.includes(
        appConfig.get2LetterCountryCode()
    );
    const isEu = EU_COUNTRIES.includes(appConfig.get2LetterCountryCode());
    const showFordPassRewards: boolean =
        isFord && isNa && !!fordPassRewardsContent;
    const loyaltyPointsResponse = showFordPassRewards
        ? useLoyaltyPointsDataForTile()
        : null;
    const [loyaltyPointsData, setLoyaltyPointsData] = useState<PointsInfo>();
    const showInitialsLogo: boolean =
        isFord && isNa && !!firstName && !!lastName;
    const showPersonIconLogo: boolean = isFord && !isNa;
    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();

    const getOnOrderStatusVin = () => {
        const vin = [];
        for (const key in activeVehiclesOnOrder) {
            if (activeVehiclesOnOrder[key].vin) {
                vin.push(activeVehiclesOnOrder[key].vin);
            }
        }
        return vin;
    };

    /**
     *
     * @param cacheable - if cacheable is true then profile data will return from cache otherwise profile service will trigger
     * if param not passed, by default function return response from cache
     */

    const handleCurrentDisplay = (value: number) => {
        setDisplayedCard(value);
    };

    const updateVehicleData = async (cacheable = true) => {
        if (!cacheable) {
            cacheService.evictProfileServiceCache();
            cacheService.evictProfileLiteCache();
        }

        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setIsAuthenticated(isAuthenticated);
                    profileService.request().then((profileService) => {
                        if (profileService) {
                            setFirstName(profileService.profile.firstName);
                            setLastName(profileService.profile.lastName);
                            setMemberId(profileService.profile.memberId);

                            if (profileService.vehicles.length > 0) {
                                // Update vehicles count to manage the dynamic button in the connected service tile
                                props.vehiclesCount(
                                    profileService.vehicles.length
                                );
                                props.setCurrentVehiclesCount(
                                    profileService.vehicles.length
                                );
                                const sortedVehicleProfiles = [
                                    ...profileService.vehicles,
                                ].sort(
                                    (vehicle1, vehicle2) =>
                                        parseInt(vehicle2.modelYear!, 10) -
                                        parseInt(vehicle1.modelYear!, 10)
                                );
                                const vehicles = sortedVehicleProfiles.map(
                                    (vehicleDetail) => {
                                        return {
                                            year: parseInt(
                                                vehicleDetail.modelYear,
                                                10
                                            ),
                                            nickName: vehicleDetail.nickName,
                                            make: vehicleDetail.make,
                                            model: vehicleDetail.modelName,
                                            vin: vehicleDetail.vin,
                                            preferredDealer:
                                                vehicleDetail.preferredDealer,
                                            ownerState:
                                                vehicleDetail.ownerState,
                                            fuelType: vehicleDetail.fuelType,
                                        } as VehicleAttributes;
                                    }
                                );
                                props.setVehiclesData(vehicles);
                            } else {
                                props.vehiclesCount(0);
                                props.setVehiclesData([]);
                            }
                        } else {
                            props.vehiclesCount(0);
                            props.setVehiclesData([]);
                        }
                    });
                }
            });
    };

    useEffect(() => {
        (async () => await updateVehicleData(true))();
    }, [isAuthenticated, props.isNewVehicleAdded, props.currentVehiclesCount]);

    useEffect(() => {
        if (vehiclesOnOrderSortedByPurchaseDate) {
            setActiveVehiclesOnOrder(vehiclesOnOrderSortedByPurchaseDate);
        }
    }, [props.vehiclesOnOrder]);

    useEffect(() => {
        if (loyaltyPointsResponse) {
            setLoyaltyPointsData(loyaltyPointsResponse[appConfig.brand]);
        }
    }, [loyaltyPointsResponse]);

    const cardDataProps = props.vehiclesData.map((data) => {
        const { model, vin, year, nickName, fuelType } = data;
        return {
            title: model,
            nickname: nickName,
            description: vin,
            ctaLabel: vehicleContentFragments?.carddetailscta,
            year: year,
            ctaHref: vehicleContentFragments?.carddetailsctaPath + vin,
            fuelType: fuelType,
            vehicleAttributes: data,
        };
    });
    const totalOrdersPlusGarageVehicles: number =
        cardDataProps.length + props.vehiclesOnOrder.length;
    const showAddVehicleButton: boolean =
        (!vehicleContentFragments?.hide &&
            (totalOrdersPlusGarageVehicles === 0 ||
                (props.isMobile &&
                    !props.dynamicMastheadProperties?.hasDynamicMasthead))) ||
        (!props.isMobile && totalOrdersPlusGarageVehicles > 3) ||
        (isTablet && totalOrdersPlusGarageVehicles > 2) ||
        isFord;

    useEffect(() => {
        // GVT Service Call
        if (vehicleContentFragments?.gvtMainCopy) {
            gcctService.getCurrentBookings().then((response) => {
                if (response.customerbookings) {
                    const reservedStatusBookings =
                        response.customerbookings.filter(
                            (booking) => booking.bookingStatus === 'Reserved'
                        );
                    setCustomerBookings(reservedStatusBookings);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (
            isFord &&
            notificationBannerContent?.specialAlertCtaLabelText &&
            notificationBannerContent?.specialAlertCtaHref
        ) {
            setNotificationContext(NotificationType.SpecialAlert, true);
        } else {
            setNotificationContext(NotificationType.None, false);
        }
    }, []);

    return (
        <>
            {' '}
            <article
                className={
                    totalOrdersPlusGarageVehicles
                        ? `featured-articles-carousel-container featured-articles-carousel-container-margin fmc-pt-1-xs fmc-pt-2 fmc-px-3-xs fmc-px-3-sm fmc-px-3-md fmc-px-0-lg fmc-px-0-xl`
                        : `featured-articles-carousel-container fmc-pt-1-xs fmc-pt-2 fmc-px-3-xs fmc-px-3-sm`
                }
            >
                <div className={`${isFord ? 'greeting-wrapper' : ''}`}>
                    {isMobileReimagine && props.mastheadContent && (
                        <>
                            <div className="greeting-container" id="HL3">
                                <Greeting
                                    brand={appConfig.brand}
                                    firstName={firstName || ''}
                                    lastName={lastName || ''}
                                    testId="greeting"
                                    mastheadContent={props.mastheadContent}
                                />
                            </div>

                            {props.mastheadContent &&
                                props.mastheadContent.memberId &&
                                memberId && (
                                    <MemberId
                                        memberIdFieldLabel={
                                            props.mastheadContent.memberId
                                        }
                                        memberIdNumber={memberId}
                                        className={
                                            isFord ? 'ford-member-id ' : ''
                                        }
                                    />
                                )}

                            {showInitialsLogo && (
                                <InitialsLogo
                                    isMobile={isMobileReimagine}
                                    firstName={firstName}
                                    lastName={lastName}
                                />
                            )}
                            {showPersonIconLogo && (
                                <PersonIconLogo isMobile={isMobileReimagine} />
                            )}
                            {showFordPassRewards && (
                                <FordPassRewards
                                    appConfig={appConfig}
                                    points={loyaltyPointsData?.points}
                                    expirationDate={
                                        loyaltyPointsData?.pointsExpirationDate
                                    }
                                    tier={loyaltyPointsData?.tierName}
                                    fordPassRewardsContent={
                                        fordPassRewardsContent
                                    }
                                    hasBeenActivated={
                                        loyaltyPointsData?.memberStatus &&
                                        loyaltyPointsData?.memberStatus === 'A'
                                    }
                                    isMobile
                                />
                            )}
                        </>
                    )}
                    {!isMobileReimagine && props.mastheadContent && (
                        <>
                            <div className="masthead-row-1">
                                <div className="greeting-container" id="HL3">
                                    <Greeting
                                        brand={appConfig.brand}
                                        firstName={firstName || ''}
                                        lastName={lastName || ''}
                                        testId="greeting"
                                        mastheadContent={props.mastheadContent}
                                    />
                                </div>

                                {props.mastheadContent.memberId && memberId && (
                                    <MemberId
                                        memberIdFieldLabel={
                                            props.mastheadContent.memberId
                                        }
                                        memberIdNumber={memberId}
                                        className={
                                            isFord ? 'ford-member-id ' : ''
                                        }
                                    />
                                )}
                            </div>
                            <div className="masthead-row-2">
                                {showInitialsLogo && (
                                    <InitialsLogo
                                        isMobile={isMobileReimagine}
                                        firstName={firstName}
                                        lastName={lastName}
                                    />
                                )}
                                {showPersonIconLogo && (
                                    <PersonIconLogo
                                        isMobile={isMobileReimagine}
                                    />
                                )}
                                {showFordPassRewards && (
                                    <FordPassRewards
                                        appConfig={appConfig}
                                        points={loyaltyPointsData?.points}
                                        expirationDate={
                                            loyaltyPointsData?.pointsExpirationDate
                                        }
                                        tier={loyaltyPointsData?.tierName}
                                        fordPassRewardsContent={
                                            fordPassRewardsContent
                                        }
                                        hasBeenActivated={
                                            loyaltyPointsData?.memberStatus &&
                                            loyaltyPointsData?.memberStatus ===
                                                'A'
                                        }
                                        isMobile={isMobileReimagine}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>

                {isFord &&
                vehicleContentFragments?.viewOrderHistoryCtaText &&
                (props.activeOrders?.length ||
                    activeVehiclesOnOrder?.length) ? (
                    <ActiveOrdersCarousel
                        appConfig={appConfig}
                        activeOrders={props.activeOrders}
                        activeVehiclesOnOrder={activeVehiclesOnOrder}
                        isMobile={props.isMobile}
                        vehicleCarouselContent={vehicleContentFragments}
                        orderHistoryData={props.orderHistoryData}
                    />
                ) : null}

                {!props.dynamicMastheadProperties?.hasDynamicMasthead &&
                isLincoln ? (
                    <p
                        className={`fmc-type--body1 fds-color__text--white fds-align--center ${
                            totalOrdersPlusGarageVehicles === 0
                                ? `fmc-mb-4-sm fmc-mb-4-md fmc-pb-1-md fmc-mb-4-lg fmc-pb-1-lg fmc-mb-4-xl fmc-pb-1-xl`
                                : `fmc-px-0`
                        } fmc-pt-1`}
                    >
                        {totalOrdersPlusGarageVehicles
                            ? props.mastheadContent?.withVehicleDescription
                            : props.mastheadContent?.noVehicleDescription}
                    </p>
                ) : null}
                <div className="add-vehicle-section">
                    <div className="add-vehicle-button-container ">
                        <div className="my-vehicles-header">
                            {props.vehicleCarouselContent.myVehiclesHeader}
                        </div>
                        {props.addVehicleContent?.addVehicleButton &&
                        showAddVehicleButton ? (
                            <div className="no-vehicles-button">
                                <AddVehicle
                                    addVehicleContent={props.addVehicleContent}
                                    ctaType="button"
                                    hasDynamicMasthead={
                                        !!props.selectedDynamicMasthead
                                    }
                                    buttonClass={
                                        'feature-articles-carousel-button-add'
                                    }
                                    buttonText={
                                        props.addVehicleContent
                                            ?.addVehicleButton
                                    }
                                    addButtonPlusSign={false}
                                    updateVehiclesData={updateVehicleData}
                                    isEURegion={isEu}
                                    setFocusToAddButton={
                                        props.deletedVehicleState
                                    }
                                    displayAddButton
                                    setIsLoading={props.setIsLoading}
                                    currentVehiclesCount={
                                        props.currentVehiclesCount
                                    }
                                    fromOwnerAddVehicle={false}
                                    fromConnectedServices={false}
                                    fromSmartTile={false}
                                    setCurrentVehiclesCount={
                                        props.setCurrentVehiclesCount
                                    }
                                    onOrderStatusVin={getOnOrderStatusVin()}
                                    vehiclesOnOrder={props.vehiclesOnOrder}
                                    garageVehicles={props.vehiclesData}
                                    setIsNewVehicleAdded={
                                        props.setIsNewVehicleAdded
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                    {cardDataProps?.length > 0 && isFord && (
                        <div className="display-count">
                            {props.vehicleCarouselContent.displayingLabel}{' '}
                            {displayedCard}{' '}
                            {props.vehicleCarouselContent.ofLabel}{' '}
                            {props.currentVehiclesCount}
                        </div>
                    )}
                </div>
                {props.openAddVehicleModal &&
                typeof props.currentVehiclesCount === 'number' ? (
                    <AddVehicle
                        addVehicleContent={props.addVehicleContent}
                        updateVehiclesData={updateVehicleData}
                        isEURegion={isEu}
                        displayAddButton={false}
                        setIsLoading={props.setIsLoading}
                        currentVehiclesCount={props.currentVehiclesCount}
                        setCurrentVehiclesCount={props.setCurrentVehiclesCount}
                        fromOwnerAddVehicle={props.fromAddVehicleParameter}
                        fromConnectedServices={props.fromConnectedServices}
                        onOrderStatusVin={getOnOrderStatusVin()}
                        vehiclesOnOrder={props.vehiclesOnOrder}
                        garageVehicles={props.vehiclesData}
                        setIsNewVehicleAdded={props.setIsNewVehicleAdded}
                    />
                ) : null}

                {totalOrdersPlusGarageVehicles &&
                !vehicleContentFragments?.hide ? (
                    <VehicleCarousel
                        appConfig={appConfig}
                        addVehicleContent={props.addVehicleContent}
                        cards={cardDataProps}
                        vehiclesOnOrder={activeVehiclesOnOrder}
                        updateVehiclesData={updateVehicleData}
                        title="Carousel"
                        subtitle="carousel"
                        ofLabel={vehicleContentFragments?.ofLabel}
                        carouselText={vehicleContentFragments}
                        isMobile={props.isMobile}
                        isTablet={isTablet}
                        onRemove={async () => {
                            await updateVehicleData(false);
                            sessionStorage.removeItem(
                                PREFERRED_DEALER_STORAGE_KEY
                            );
                            props.setDeletedVehicleState(true);
                        }}
                        setIsLoading={props.setIsLoading}
                        dynamicMastheadProperties={
                            props.dynamicMastheadProperties
                        }
                        currentVehiclesCount={props.currentVehiclesCount}
                        setCurrentVehiclesCount={props.setCurrentVehiclesCount}
                        onOrderStatusVin={getOnOrderStatusVin()}
                        customerBookings={customerBookings}
                        garageVehicles={props.vehiclesData}
                        deliveredVehicles={props.deliveredVehicles}
                        showCarousel={!vehicleContentFragments.hide}
                        setIsNewVehicleAdded={props.setIsNewVehicleAdded}
                        currentCard={handleCurrentDisplay}
                    />
                ) : null}
            </article>
        </>
    );
};
