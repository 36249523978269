import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { consentService } from '../consent-service/consent-service';
import { cafexService } from '../cafex-service/cafex-service';
import { SUPPRESS_US_COOKIE_BANNER_COUNTRIES } from '@/constants';

class ScriptService {
    public setMouseflowScript() {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        if (appConfiguration.mouseflowId) {
            console.debug('Mouseflow initialised');
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.defer = true;
            script.src = `//cdn.mouseflow.com/projects/${appConfiguration.mouseflowId}.js`;
            script.async = true;
            document.body.appendChild(script);
            document.getElementsByTagName('head')[0].appendChild(script);
        } else console.debug('No Mouseflow configuration found');
    }

    public loadFMA() {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        const origin = `https://${window.location.hostname}${appConfiguration.root}`;
        const fmaTag = document.createElement('script');
        const queryParams = new URLSearchParams(window.location.search);
        const locale = queryParams.get('locale');
        const localeRegex = /^[a-zA-Z]{2}-[a-zA-Z]{2}$/;
        const isValidLocale = locale && localeRegex.test(locale);
        fmaTag.src = appConfiguration.fmaUrl;
        fmaTag.setAttribute('data-fma-script', '');
        const clientId = appConfiguration.clientId
            ? appConfiguration.clientId
            : process.env.REACT_APP_IBM_CID;
        fmaTag.setAttribute('data-client-id', clientId || '');
        fmaTag.setAttribute('data-base-path', appConfiguration.root);
        fmaTag.setAttribute(
            'data-base-path-redirection',
            (
                appConfiguration.brand &&
                appConfiguration.languageRegionCode &&
                appConfiguration.root ===
                    `/${appConfiguration.brand}/${appConfiguration.languageRegionCode}/`
            ).toString()
        );
        fmaTag.setAttribute('data-enable-guest-guid', 'false');
        fmaTag.setAttribute('data-app-id', appConfiguration.appId);
        fmaTag.setAttribute('data-property-key', 'brand_ford');
        fmaTag.setAttribute('data-region', appConfiguration.fmaRegion);
        fmaTag.setAttribute('data-fs-url', appConfiguration.fmaFsUrl);
        fmaTag.setAttribute('data-logout-url', origin);
        fmaTag.setAttribute('data-origin-url', origin);
        if (
            isValidLocale &&
            SUPPRESS_US_COOKIE_BANNER_COUNTRIES.includes(locale)
        ) {
            console.log('Suppressing cookie banner for locale: ', locale);
            fmaTag.setAttribute('data-lang', locale.split('-').join('_'));
        } else {
            fmaTag.setAttribute(
                'data-lang',
                appConfiguration.languageRegionCode === 'no-no'
                    ? 'nb_no'
                    : appConfiguration.languageRegionCode.split('-').join('_')
            );
        }
        document.body.appendChild(fmaTag);
    }

    public unloadFMA() {
        const fmaTag = document.body.querySelector('script[data-fma-script]');
        if (fmaTag && document.body.contains(fmaTag)) {
            document.body.removeChild(fmaTag);
        }
    }

    public loadOneTrustCookieBanner() {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        const oneTrustTag = document.createElement('script');
        oneTrustTag.setAttribute(
            'src',
            'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        );
        oneTrustTag.setAttribute('type', 'text/javascript');
        oneTrustTag.setAttribute('charset', 'UTF-8');
        if (appConfiguration.brand === 'lincoln') {
            oneTrustTag.setAttribute(
                'data-domain-script',
                'e68b490a-bbfa-4cf0-a492-de3c092da1df'
            );
        } else {
            oneTrustTag.setAttribute(
                'data-domain-script',
                '474f6f1e-8e77-40c9-97fd-ab58b6e08de0'
            );
        }
        document.getElementsByTagName('head')[0].appendChild(oneTrustTag);
    }

    public loadEvidonCookieBanner() {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        if (appConfiguration.countryCode === 'ca') {
            const evidonTag = document.createElement('script');
            evidonTag.setAttribute('id', 'evidon-script');
            evidonTag.setAttribute('src', 'https://c.evidon.com/dg/dg.js');
            evidonTag.async = true;
            evidonTag.defer = true;
            evidonTag.setAttribute('companyid', '1063');
            const evidonInnerScriptTag = document.createElement('script');
            evidonInnerScriptTag.setAttribute('id', 'evidon-inner-script');
            evidonInnerScriptTag.innerHTML =
                '!function(e){function n(e,n,o){var i=document,t="script",c=i.getElementsByTagName(t)[0];c||(c=i.head);var s=i.createElement(t);s.async=o,s.id=e,s.src=n,s.charset="utf-8",c.parentNode.insertBefore(s,c)}window.evidon={},window.evidon.id=e,window.evidon.test=!1;var o="//c.evidon.com/",i=function e(){var n=window.location.hostname.split(".");if(2===n.length)i=n[0];else if(n.length>2){var o,t=n[n.length-2];i=-1!==["co","com","info","web","info","gov","edu","biz","net","org"].indexOf(o=t)||-1!==["uk","us","fr","es","de","at","au","ae","be","br","ca","ch","cn","co","cz","dk","eg","eu","fi","gb","gr","hk","hr","hu","ie","in","jp","mx","nl","no","nz","pl","ro","ru","se"].indexOf(o)?n[n.length-3]:t}return i}(),t=o+"sitenotice/";n("evidon-notice",t+"evidon-sitenotice-tag.js",!1),n("evidon-location",o+"geo/country.js",!0),n("evidon-themes",t+e+"/snthemes.js",!0),i&&n("evidon-settings",t+e+"/"+i+(window.evidon.test?"/test":"")+"/settingsV2.js",!0),window.evidon.priorConsentCallback=function(e,n,o){},window.evidon.closeCallback=function(){},window.evidon.consentWithdrawnCallback=function(){},window.evidon.consentDeclinedCallback=function(){window.evidon.notice.dropSuppressionCookie(30)}}(1063);';
            document.body.appendChild(evidonTag);
            document.body.appendChild(evidonInnerScriptTag);
        }
    }

    public loadAnalyticsScript() {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        if (appConfiguration.analyticsEmbedScriptUrl) {
            const analyticsTag = document.createElement('script');
            analyticsTag.src = appConfiguration.analyticsEmbedScriptUrl;
            document.head.appendChild(analyticsTag);
        }
    }

    public loadWalletScript = (callback) => {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        const existingScript = document.getElementById('walletScript');

        if (!existingScript) {
            const walletTag = document.createElement('script');
            walletTag.id = 'walletScript';
            walletTag.src = appConfiguration.walletUrl;
            document.head.appendChild(walletTag);

            walletTag.onload = () => {
                if (callback) callback();
            };
        }

        if (existingScript && callback) callback();
    };

    public loadStripeScript = (): void => {
        const stripeScriptTag = document.createElement('script');
        stripeScriptTag.id = 'stripeScript';
        stripeScriptTag.src = 'https://js.stripe.com/v3/';
        document.head.appendChild(stripeScriptTag);
    };

    public loadConnectedServicesScript() {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        const subscriptionTag = document.createElement('script');
        subscriptionTag.id = 'subscriptionScript';

        if (appConfiguration.subscriptionUrl) {
            subscriptionTag.src = appConfiguration.subscriptionUrl;
            subscriptionTag.async = true;
            document.head.appendChild(subscriptionTag);
        }
    }

    public loadCafexScript(removeFlag?: boolean) {
        const cafexScriptPath = cafexService.getCafexScriptPath();
        const isWebview =
            typeof window !== undefined
                ? (window as any).SERVER_CONTEXT.webview === 'true'
                : false;
        if (cafexScriptPath && !isWebview) {
            const cafexTag = document.createElement('script');
            cafexTag.setAttribute('src', cafexScriptPath);
            cafexTag.setAttribute('id', 'cafexscriptid');
            if (consentService.nonEssentialScriptsAllowed() && !removeFlag) {
                cafexTag.setAttribute('class', 'optanon-category-6');
                document.body.appendChild(cafexTag);
            } else if (removeFlag) {
                const cafexEle = document.getElementById('cafexscriptid');
                cafexEle && document.body.removeChild(cafexEle);
            }
        }
    }

    public async loadBingMapsScript(callback, id?: string) {
        const appConfig = new AppConfigurationService().getAppConfiguration();

        if (appConfig.brand === 'ford' || appConfig.brand === 'lincoln') {
            const bingMapScript = document.createElement('script');
            const src =
                'https://www.bing.com/api/maps/mapcontrol?key=Ag5iZ-Ijk6WI40aWrWa-gwOzCJPB0jYdQPXKFq_pB94h0mQ7JgzkrvvWtvxXYYh_';

            bingMapScript.setAttribute('type', 'text/javascript');
            bingMapScript.src = src;
            if (id) {
                bingMapScript.id = id;
            }
            bingMapScript.async = true;
            bingMapScript.defer = true;

            document.head.appendChild(bingMapScript);
            bingMapScript.onload = () => {
                if (callback) {
                    callback();
                }
            };
        }
    }

    public loadGoogleMapsScript(callback) {
        const appConfig = new AppConfigurationService().getAppConfiguration();

        if (appConfig.brand === 'ford' || appConfig.brand === 'lincoln') {
            const googleMapScript = document.createElement('script');
            const src =
                'https://maps.googleapis.com/maps/api/js?&client=gme-fordmotorcompany2&libraries=places';

            googleMapScript.setAttribute('type', 'text/javascript');
            googleMapScript.src = src;
            googleMapScript.async = true;
            googleMapScript.defer = true;
            document.head.appendChild(googleMapScript);
            googleMapScript.onload = () => {
                if (callback) {
                    callback();
                }
            };
        }
    }

    public loadRecaptchaScript() {
        const recaptchaTag = document.createElement('script');
        recaptchaTag.id = 'recaptchaScript';
        recaptchaTag.src = 'https://www.google.com/recaptcha/api.js';
        recaptchaTag.async = true;
        recaptchaTag.defer = true;
        document.head.appendChild(recaptchaTag);
    }
}

export const scriptService = new ScriptService();
export default ScriptService;
