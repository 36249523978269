import React from 'react';
import { InputField } from '@common/form-fields/form-fields';

const LastName = (props) => {
    const {
        label,
        focus,
        setFocusToField,
        userData,
        handleChange,
        error,
        inputValidations,
        isSecondLastName = false,
    } = props;

    const fieldName = isSecondLastName ? 'secondLastName' : 'lastName';
    return (
        <>
            {label && (
                <InputField
                    id={fieldName}
                    dataTestId={fieldName}
                    value={userData}
                    name={fieldName}
                    handleChange={handleChange}
                    label={label}
                    showLabel={focus.lastname}
                    className=""
                    validationRules={
                        inputValidations[fieldName].validationRules
                    }
                    validationPattern={
                        inputValidations[fieldName].validationPattern
                    }
                    validationCharLimit={
                        inputValidations[fieldName].validationCharLimit
                    }
                    error={error[fieldName]}
                    onFocus={() => setFocusToField(fieldName, true)}
                    onBlur={() => {
                        userData.length == 0 &&
                            setFocusToField(fieldName, false);
                    }}
                    placeholder={label}
                />
            )}
        </>
    );
};

export default LastName;
