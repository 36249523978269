import React, { ReactElement, useEffect, useState } from 'react';

import './return-button.scss';
import { findPathByAlias } from '@routes/routesList';
import { ReturnButtonProps } from './hook/use-return-button-content';
import { useLocation } from 'react-router-dom';
import TertiaryButton from '@common/tertiary-button/tertiary-button';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

interface Props {
    returnButtonContent: ReturnButtonProps;
    fromSignInAndSecurityErrorPage?: boolean;
    fromPreferredDealerPage?: boolean;
    onClick?: () => void;
    ariaLabel?: string;
    dataTestId?: string;
}
const ReturnButton = (props: Props): ReactElement => {
    const dashboardView = findPathByAlias('AccountDashboardView');
    const signInAndSecurityView = findPathByAlias('SignInCredentialsView');
    const dashboardPath =
        typeof dashboardView === 'object' ? dashboardView[1] : dashboardView;
    const [showReturnButton, setShowReturnButton] = useState<boolean>(false);
    const fromWebview =
        new URLSearchParams(useLocation().search).get('webview') || undefined;
    const appConfig = new AppConfigurationService();
    const fromConnectedServicesAuNz =
        useLocation().pathname.includes('connected-services') &&
        ['au', 'nz'].includes(appConfig.currentCountryCode);
    const dashboardPathForConnectedServices = 'account-dashboard';

    const generateReturnLink = (): string => {
        if (props.fromSignInAndSecurityErrorPage) {
            return signInAndSecurityView;
        } else if (fromConnectedServicesAuNz) {
            return dashboardPathForConnectedServices;
        }

        return dashboardPath;
    };

    useEffect(() => {
        if (!fromWebview) {
            setShowReturnButton(true);
        }
    }, [fromWebview]);

    return (
        props.returnButtonContent &&
        showReturnButton && (
            <div className="return-button" data-testid="return-button">
                <TertiaryButton
                    labelText={props.returnButtonContent.returnText}
                    onClick={props.onClick}
                    link={generateReturnLink()}
                    ariaLabel={props.ariaLabel}
                    internal={!fromConnectedServicesAuNz}
                    chevronDirection={'left'}
                    dataTestId={props.dataTestId}
                />
            </div>
        )
    );
};

export default ReturnButton;
