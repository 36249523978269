import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';
import { VehicleDetail } from '@models/profile-with-vehicles';

export interface VehicleTypeData {
    smartCharge?: string;
    wifiHotspot?: string;
    sdn?: any;
    model: string;
    tirePressureMonitoring?: string;
    VINLookupCanITow?: string;
    grossVehicleWeightRatingPounds?: string;
    grossCombinedWeightRatingPounds?: string;
    maximumPayloadPounds?: string;
    maximumConventionalPounds?: string;
    maxFifthPounds?: string;
    maxGooseneckPounds?: string;
    warrantyStartDate?: string;
    year: string;
    make: string;
    asBuiltCountry?: string;
    bodyStyleDescription?: string;
    fuelType?: string;
    engineType?: string;
    vin: string;
    citivanVin?: boolean;
    blueCruiseGeneration?: string;
}

export interface VehicleType {
    vehicleData?: VehicleTypeData;
    isBev?: boolean;
    isWifiCapable?: boolean;
    isConnected: boolean;
    isIolmCapable?: boolean;
    isTpmAvailable?: boolean;
}

export const findNewestVehicle = (vehicles: VehicleDetail[]): VehicleDetail => {
    if (!vehicles || vehicles.length === 0) {
        return null;
    }

    return vehicles.reduce((newest, vehicle) => {
        return parseInt(vehicle.modelYear, 10) > parseInt(newest.modelYear, 10)
            ? vehicle
            : newest;
    }, vehicles[0]);
};

export default class VehicleTypeService {
    async requestVehicleType(
        vin: string,
        locale: string,
        country: string
    ): Promise<VehicleType> {
        const params = {
            locale,
            country,
        };
        const headers = { ...HttpService.getConsumerKeyRequestHeaders(), vin };
        const vehicleTypeUrl = DSL_BASE_URL + DSL_API_PATH.VEHICLE_TYPE;
        let response;

        try {
            response = await HttpService.get<VehicleType>(
                vehicleTypeUrl,
                true,
                {
                    params,
                    headers,
                }
            );
        } catch (error) {
            console.error(error);
        }

        return response?.data || { isConnected: false };
    }
}
