import React from 'react';
import PersonIcon from '@common/person-icon/person-icon';
import RightCaret from '@sections/secondary-nav/components/right-caret';
import './user-account-tile.scss';
import { findPathByAlias } from '@routes/routesList';
import { SecondaryButton } from '@common/index';

interface UserAccountTileProps {
    firstName: string;
    onClick: () => void;
}

const UserAccountTile = (props: UserAccountTileProps) => {
    const { firstName } = props;

    return (
        <div data-testid="user-account-tile">
            <SecondaryButton
                internal
                aria-label={`Go to ${firstName}`}
                className="user-account-tile-link"
                dataTestId="user-account-tile-link"
                link={findPathByAlias('AccountDashboardView')}
                onClick={props.onClick}
            >
                <div
                    className="user-account-tile__container"
                    data-testid="secondary-nav-user-account-tile"
                >
                    <PersonIcon
                        className="secondary-nav-person-icon"
                        color="#333333"
                        testId="secondary-nav-mobile-person-icon"
                    />

                    <p data-testid="user-account-text">{firstName}</p>

                    <RightCaret color="black" />
                </div>
            </SecondaryButton>
        </div>
    );
};

export default UserAccountTile;
