export const bannedNames = new Set<string>([
    'page-not-found',
    'terms-of-use',
    'pricing',
    'privacy-policy',
    'customers',
    'request-form',
    'request-solution',
    'release-notes',
    'api-reference',
    'video-tutorials',
    'acts',
    'schedules',
    'account',
    'sign-up',
    'sign-in-discourse',
    'admin',
    'change-password',
    'enroll-account',
    'forgot-password',
    'reset-password',
    'sign-in',
    'verify-email',
    'live-status',
    'browser-info',
    'health-check',
    'api',
    'change-log',
    'dashboard',
    'administration',
    'act',
    'contact',
    'for-business',
    'for-developers',
    'developers',
    'business',
    'integrations',
    'job',
    'setting',
    'settings',
    'privacy',
    'policy',
    'assets',
    'help',
    'config',
    'configuration',
    'terms',
    'hiring',
    'hire',
    'status',
    'status-page',
    'solutions',
    'support',
    'market',
    'marketplace',
    'download',
    'downloads',
    'username',
    'users',
    'user',
    'login',
    'logout',
    'signin',
    'sign',
    'signup',
    'sign-out',
    'plugins',
    'plug-ins',
    'reset',
    'password',
    'passwords',
    'profile-photos',
    'profiles',
    'true',
    'false',
    'images',
    'image',
    'community',
    'storage',
    'storages',
    'video',
    'knowledgebase',
    'forum',
    'health',
    'scheduler',
    'sdk',
    'cookies',
    'cookie-policy',
    'cookies-policy',
    'access',
    'accounts',
    'activate',
    'activities',
    'activity',
    'address',
    'administrator',
    'advertising',
    'affiliate',
    'affiliates',
    'analysis',
    'analytics',
    'android',
    'anonymous',
    'archive',
    'archives',
    'auth',
    'authentication',
    'backup',
    'billing',
    'bug',
    'cache',
    'call',
    'campaign',
    'cancel',
    'captcha',
    'career',
    'careers',
    'categories',
    'category',
    'changelog',
    'chat',
    'check',
    'checking',
    'checkout',
    'client',
    'clients',
    'code',
    'codereview',
    'comercial',
    'comment',
    'comments',
    'communities',
    'company',
    'compare',
    'connect',
    'contact-us',
    'contact_us',
    'contactus',
    'create',
    'data',
    'default',
    'delete',
    'demo',
    'dev',
    'devel',
    'developer',
    'direct_messages',
    'directory',
    'domain',
    'ecommerce',
    'email',
    'employment',
    'enterprise',
    'entries',
    'entry',
    'error',
    'errors',
    'event',
    'exit',
    'faq',
    'feature',
    'features',
    'feedback',
    'feeds',
    'file',
    'files',
    'fleet',
    'fleets',
    'forgot',
    'form',
    'forums',
    'github',
    'homepage',
    'host',
    'hosting',
    'hostname',
    'info',
    'information',
    'inquiry',
    'invitations',
    'invite',
    'issue',
    'issues',
    'language',
    'languages',
    'legal',
    'license',
    'link',
    'links',
    'list',
    'lists',
    'log',
    'log-in',
    'log-out',
    'log_in',
    'log_out',
    'logs',
    'mail',
    'maintenance',
    'manager',
    'manual',
    'map',
    'maps',
    'marketing',
    'master',
    'member',
    'members',
    'message',
    'messages',
    'notification',
    'notifications',
    'notify',
    'null',
    'oauth',
    'oauth_clients',
    'offers',
    'official',
    'online',
    'openid',
    'organization',
    'organizations',
    'owner',
    'owners',
    'payment',
    'privacy_policy',
    'privacypolicy',
    'private',
    'product',
    'products',
    'profile',
    'project',
    'projects',
    'promo',
    'public',
    'query',
    'read',
    'readme',
    'register',
    'registration',
    'release',
    'remove',
    'report',
    'reports',
    'repositories',
    'repository',
    'request',
    'requests',
    'secure',
    'security',
    'send',
    'server',
    'server-info',
    'server-status',
    'service',
    'services',
    'setup',
    'share',
    'sign_in',
    'sign_up',
    'signout',
    'stats',
    'subscribe',
    'subscriptions',
    'system',
    'term',
    'terms-of-service',
    'terms_of_service',
    'termsofservice',
    'test',
    'test1',
    'test2',
    'test3',
    'testing',
    'tests',
    'tutorial',
    'unfollow',
    'unsubscribe',
    'update',
    'upload',
    'uploads',
    'url',
    'version',
    'yourdomain',
    'yourname',
    'yoursite',
    'yourusername',
    'Firmware',
    'Software',
    'OTA',
    'guides',
    'fordpass',
    'lincoln way',
    'upgrade',
    'Ford App',
    'Lincoln App',
    'Customer Service',
    'cyber',
    'vehicle',
    'technician',
    'firstname',
    'lastname',
    'fname',
    'lname',
    'page-introuvable',
    "conditions-d'utilisation",
    'tarification',
    'politique-de-confidentialité',
    'formulaire-de-demande',
    'demander-solution',
    'notes-de-version',
    'référence-api',
    'tutoriels-vidéo',
    'actes',
    'horaires',
    'compte',
    "s'inscrire",
    'discours-enregistrement',
    'modifier-le-mot-de-passe',
    'enregistrer-compte',
    'mot-de-passe-oublié',
    'réinitialiser-le-mot-de-passe',
    "s'enregistrer",
    "vérifier-l'adresse-courriel",
    'état-en-direct',
    'navigateur-info',
    'vérification-santé',
    'journal-des-modifications',
    'tableau de bord',
    'agir',
    'contacter',
    'pour-entreprises',
    'pou-développeurs',
    'développeurs',
    'entreprises',
    'intégrations',
    'travail',
    'réglage',
    'réglages',
    'confidentialité',
    'politique',
    'actifs',
    'aide',
    'conditions',
    'embauche en cours',
    'embaucher',
    'état',
    "page-d'état",
    'soutien',
    'marché',
    'marché en ligne',
    'téléchargement',
    'téléchargements',
    "nomd'utilisateur",
    'utilisateurs',
    'utilisateur',
    'se connecter',
    'déconnexion',
    'signer',
    'se-déconnecter',
    'réinitialiser',
    'mot de passe',
    'mots de passe',
    'photos-de-profil',
    'profils',
    'vrai',
    'faux',
    'communauté',
    'stockage',
    'stockages',
    'vidéo',
    'base de connaissances',
    'vérification santé',
    'santé',
    'planificateur',
    'politique-de-cookies',
    'politique-sur-les-cookies',
    'accès',
    'comptes',
    'activer',
    'activités',
    'activité',
    'adresse',
    'administrateur',
    'publicité',
    'affilier',
    'analyse',
    'analytiques',
    'anonyme',
    'archiver',
    'authentifié',
    'authentification',
    'sauvegarde',
    'facturation',
    'bogue',
    'entreprise',
    'appeler',
    'campagne',
    'annuler',
    'carrière',
    'carrières',
    'catégories',
    'catégorie',
    'journaldesmodifications',
    'vérifier',
    'vérification',
    'paiement',
    'révisionducode',
    'commercial',
    'commentaire',
    'commentaires',
    'communautés',
    'comparer',
    'config.',
    'connecter',
    'contactez-nous',
    'contactez_nous',
    'contacteznous',
    'créer',
    'données',
    'défaut',
    'supprimer',
    'démo',
    'dével',
    'développeur',
    'messages_directs',
    'répertoire',
    'domaine',
    'commerce électronique',
    'courriel',
    'emploi',
    'entrées',
    'entrée',
    'erreur',
    'erreurs',
    'événement',
    'quitter',
    'fonction',
    'fonctions',
    'fluxs',
    'fichier',
    'fichiers',
    'flotte',
    'flottes',
    'oublié',
    'formulaire',
    "page d'accueil",
    'héberger',
    'hébergement',
    "nomd'hôte",
    'question',
    'inviter',
    'problème',
    'problèmes',
    'langue',
    'langues',
    'légal',
    'licence',
    'lien',
    'liens',
    'liste',
    'listes',
    'journal',
    'se-connecter',
    'se_connecter',
    'journaux',
    'courrier',
    'entretien',
    'gestionnaire',
    'manuel',
    'carte',
    'cartes',
    'maître',
    'membre',
    'membres',
    'notifier',
    'nul',
    'offres',
    'officiel',
    'en ligne',
    'organisation',
    'organisations',
    'propriétaire',
    'propriétaires',
    'politique_de_confidentialité',
    'politiquedeconfidentialité',
    'privé',
    'produit',
    'produits',
    'profil',
    'projet',
    'projets',
    'requête',
    'lire',
    'lisezmoi',
    'enregistrer',
    'enregistrement',
    'publication',
    'rapport',
    'rapports',
    'référentiels',
    'référentiel',
    'demande',
    'demandes',
    'réinitialisation',
    'sécuriser',
    'sécurité',
    'envoyer',
    'serveur',
    'info-sur-serveur',
    'état-du-serveur',
    'partager',
    'sedéconnecter',
    'inscription',
    'statistiques',
    "s'abonner",
    'abonnements',
    'système',
    'condition',
    'conditions-de-service',
    'conditions_de_service',
    'conditionsdeservice',
    'tutoriel',
    'ne plus suivre',
    'se désabonner',
    'mise à jour',
    'télécharger',
    'votredomaine',
    'votrenom',
    'votresite',
    "votrenomd'utilisateur",
    'Micrologiciel',
    'Logiciel',
    'mise à niveau',
    'service à la clientèle',
    'véhicule',
    'technicien',
    'prénom',
    'nomdefamille',
    'nfamille',
    'page introuvable',
    "conditions d'utilisation",
    'politique de confidentialité',
    'formulaire de demande',
    'demander solution',
    'notes de version',
    'référence API',
    'tutoriels vidéo',
    'discours enregistrement',
    'modifier le mot de passe',
    'enregistrer compte',
    'mot de passe oublié',
    'réinitialiser le mot de passe',
    "vérifier l'adresse courriel",
    'état en direct',
    'info sur le navigateur',
    'journal des modifications',
    'pour les entreprises',
    'pour les développeurs',
    'resources',
    "page d'état",
    "nom d'utilisateur",
    'seconnecter',
    'se déconnecter',
    'plugiciels',
    'photos de profil',
    'fondé sur le savoir',
    "vérification de l'état",
    'programmateur',
    'témoin',
    'politique de cookies',
    'politique sur les cookies',
    'adresser',
    'affilié',
    'affiliés',
    'sauvegarder',
    'appel',
    'révision du code',
    'connectez',
    'fonctionnalité',
    'fonctionnalités',
    'diffusions',
    'parc automobile',
    'parcs automobiles',
    'hôte',
    "nom d'hôte",
    'dé-connexion',
    'dé_connexion',
    'OAuth clients',
    'lisez-moi',
    'libération',
    'dépôts',
    'dépôt',
    'information sur le serveur',
    'état du serveur',
    'terme',
    'termes',
    'conditions de service',
    'essai',
    'essai1',
    'essai2',
    'essai3',
    'essais',
    'votre domain',
    'votre nom',
    'votre site',
    "votre nom d'utilisateur",
    'MAJ',
    'nom de famille',
    'immobilisations',
    'Kit de développement logiciel',
    'témoin de connexion',
    'politique de témoin',
    'politique sur le témoins',
    'associé',
    'associés',
    'espaces de stockage',
    'espace de stockage',
    'test en cours',
    'hardware',
    'matériel',
]);
