import React, { useEffect, useState } from 'react';
import {
    OrderHistoryOrder,
    OrderLine,
} from '@services/oms-service/oms-service';
import './active-orders-card.scss';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { SecondaryButton } from '@common/index';
import { findPathByAlias } from '@routes/routesList';
import {
    NVC_GREEN_STATUSES,
    NVC_ORDER_STATUS_MAP,
    NVC_RED_STATUSES,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@views/order-details-view/nvc-status-map';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import { useAnalytics } from '@/hooks/use-analytics';
import { useWindowSize } from '@/hooks/use-window-size';
import { NavLink } from 'react-router-dom';

export interface ActiveOrdersCardProps {
    order: OrderHistoryOrder;
    item: OrderLine;
    orderStatusContent: OrderStatusContent;
    vehicleCarouselContent: VehicleCarouselContent;
    isCurrentCard?: boolean;
}

const ActiveOrdersCard = (props: ActiveOrdersCardProps) => {
    const {
        vehicleCarouselContent,
        order,
        item,
        orderStatusContent,
        isCurrentCard,
    } = props;
    const orderNumber = order.orderNo;
    const [fireAnalytics] = useAnalytics();
    const originalDate: Date = new Date(order.orderDate);
    const year: string = originalDate.getFullYear().toString().substring(2);
    const month: string = (originalDate.getMonth() + 1)
        .toString()
        .padStart(2, '0');
    const day: string = originalDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${month}/${day}/${year}`;
    const gray = '#e6e8ea';
    const green = '#e5f3e6';
    const red = '#f9e8ea';
    const deliveryMethod: string = item.deliveryMethod;
    const orderStatus: string = item.status.toLowerCase();
    const size = useWindowSize();
    const [imageSize, setImageSize] = useState('150');

    const generateImageSrc = (): React.CSSProperties => {
        if (item.itemDetails.primaryInformation?.imageID) {
            if (item.itemDetails.extn.extnItemSubType === 'FPP') {
                return {
                    backgroundImage: `url(${vehicleCarouselContent.performancePartsImageUrl
                        .replace(
                            '[partNumber]',
                            item.itemDetails.primaryInformation.imageID
                        )
                        .replaceAll('[size]', imageSize)}`,
                    backgroundSize: 'cover',
                };
            } else {
                return {
                    backgroundImage: `url(${vehicleCarouselContent.accessoriesImageUrl
                        .replace(
                            '[partNumber]',
                            item.itemDetails.primaryInformation.imageID
                        )
                        .replaceAll('[size]', imageSize)}`,
                    backgroundSize: 'cover',
                };
            }
        } else {
            return {
                backgroundImage: `url(${vehicleCarouselContent.accessoriesImageUrl
                    .replace('[partNumber]', 'No_Image_Ford.jpg')
                    .replaceAll('[size]', '250')}`,
                backgroundSize: 'auto',
            };
        }
    };

    const generatePartName = (): string => {
        const partName = item.item.itemShortDesc;

        if (partName.length > 50) {
            return `${partName.substring(0, 50)}...`;
        } else if (!partName.length) {
            return '-';
        }

        return partName;
    };

    const chooseStatusBackgroundColor = (status: string): string => {
        if (NVC_GREEN_STATUSES.includes(status.toLowerCase())) {
            return green;
        } else if (NVC_RED_STATUSES.includes(status.toLowerCase())) {
            return red;
        }

        return gray;
    };

    const determineDisplayStatus = (status: string): string => {
        let displayStatus = '';
        const deliveryMethodTransformed =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].find(
            (statusOption: OrderStatusOption) => {
                if (statusOption.options.includes(status)) {
                    displayStatus = orderStatusContent[statusOption.name];
                }
            }
        );

        return displayStatus;
    };

    const assignClassToCurrentCard = (): string => {
        return isCurrentCard ? 'current-card' : '';
    };

    useEffect(() => {
        if (size.width >= 768) {
            setImageSize('250');
        }
    }, [size]);

    return (
        <div
            className={`active-orders-card__container ${assignClassToCurrentCard()}`}
            data-testid="active-orders-card"
        >
            <NavLink
                className="active-orders-card__media-area link"
                aria-label={vehicleCarouselContent.orderDetailsAriaLabel}
                to={findPathByAlias(
                    'OrderDetailsViewWithoutOrderNumber',
                    orderNumber
                )}
                onClick={() => {
                    fireAnalytics(
                        'activeOrdersCarouselOrderDetailsOnclickEvent'
                    );
                }}
            >
                <div
                    className={`active-orders-card__media-area square`}
                    style={generateImageSrc()}
                    data-testid="active-orders-card-image"
                />
            </NavLink>
            <div
                className="active-orders-card__content-area"
                data-testid="active-orders-card-content-area"
            >
                <p
                    className="active-orders-card__status"
                    data-testid="active-orders-card-status"
                    style={{
                        backgroundColor: chooseStatusBackgroundColor(
                            determineDisplayStatus(orderStatus)
                        ),
                    }}
                >
                    {determineDisplayStatus(orderStatus)}
                </p>

                <h3
                    className="active-orders-card__part-name"
                    data-testid="active-orders-card-part-name"
                >
                    {generatePartName()}
                </h3>

                <p
                    className="active-orders-card__order-number"
                    data-testid="active-orders-card-order-number"
                >
                    {`${vehicleCarouselContent.orderLabelText}: ${orderNumber}`}
                </p>

                <p
                    className="active-orders-card__date"
                    data-testid="active-orders-card-date"
                >
                    {`${vehicleCarouselContent.orderDateLabelText}: ${formattedDate}`}
                </p>

                <p
                    className="active-orders-card__number-of-items"
                    data-testid="active-orders-card-date-number-of-items"
                >
                    {`${vehicleCarouselContent.orderItemsLabelText}: ${item.originalOrderedQty}`}
                </p>

                {vehicleCarouselContent.orderDetailsCtaText && (
                    <SecondaryButton
                        internal
                        link={findPathByAlias(
                            'OrderDetailsViewWithoutOrderNumber',
                            orderNumber
                        )}
                        onClick={() => {
                            fireAnalytics(
                                'activeOrdersCarouselOrderDetailsOnclickEvent'
                            );
                        }}
                        className="active-orders-card__order-details-cta"
                        aria-label={
                            vehicleCarouselContent.orderDetailsAriaLabel
                        }
                        dataTestId="active-orders-card-order-details-cta"
                    >
                        {vehicleCarouselContent.orderDetailsCtaText}
                    </SecondaryButton>
                )}
            </div>
        </div>
    );
};

export default ActiveOrdersCard;
