import React, { useEffect, useState } from 'react';
import { OrderHistoryContent } from '../../hooks/use-order-history-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import {
    OmsOrderHistoryResponse,
    OrderHistoryOrder,
} from '@services/oms-service/oms-service';
import { NVC_PAST_ORDER_STATUSES } from '@constants';
import OrderHistoryItem from '@views/order-history-view/components/order-history-item/order-history-item';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import TertiaryButton from '@common/tertiary-button/tertiary-button';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';

interface Props {
    orderHistoryContent: OrderHistoryContent;
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    orderHistoryData: OmsOrderHistoryResponse;
    ordersFinishedLoading: boolean;
    isShowMore: boolean;
    onShowMore: () => void;
}

const className = 'order-history-component';

const OrderHistoryComponent = (props: Props) => {
    const {
        orderHistoryContent,
        orderDetailsContent,
        orderStatusContent,
        orderHistoryData,
        ordersFinishedLoading,
        isShowMore,
        onShowMore,
    } = props;
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const activeOrders: OrderHistoryOrder[] = orderHistoryData
        ? orderHistoryData.orders.filter((e) => e.isActive == 'Y')
        : [];
    const pastOrders: OrderHistoryOrder[] = orderHistoryData
        ? orderHistoryData.orders.filter((e) => e.isActive == 'N')
        : [];
    const [displayedOrderCount, setDisplayedOrderCount] = useState(0);

    useEffect(() => {
        const totalOrders = activeOrders.length + pastOrders.length;
        setDisplayedOrderCount(totalOrders);
    }, [isShowMore, activeOrders, pastOrders]);

    const showMoreOrders = () => {
        onShowMore();
    };

    return (
        <>
            <div
                className={`${className}-container`}
                data-test-id={`${className}-container`}
            >
                <h1
                    className={`${className}-title`}
                    data-testid={`${className}-title`}
                >
                    {orderHistoryContent.pageTitle}
                </h1>

                {ordersFinishedLoading && (
                    <p
                        className={
                            displayedOrderCount > 0
                                ? `${className}-subtitle`
                                : `${className}-subtitle zero-subtitle`
                        }
                        data-testid={`${className}-subtitle`}
                    >
                        {orderHistoryContent.subtitleText}{' '}
                        {orderHistoryData?.totalOrders
                            ? orderHistoryData.totalOrders
                            : '0'}{' '}
                        {orderHistoryContent.ordersName}
                    </p>
                )}

                {displayedOrderCount > 0 && orderHistoryData && (
                    <>
                        {activeOrders.map((order, index) => (
                            <OrderHistoryItem
                                key={order.orderNo}
                                order={order}
                                index={index}
                                orderHistoryContent={orderHistoryContent}
                                orderDetailsContent={orderDetailsContent}
                                orderStatusContent={orderStatusContent}
                                appConfig={appConfig}
                                className={className}
                                isActive={true}
                                totalOrders={orderHistoryData.totalOrders}
                                alwaysIncludeBottomBorder={!pastOrders.length}
                            />
                        ))}

                        {activeOrders.length && pastOrders.length ? (
                            <hr
                                className="past-orders-separator"
                                data-testid="past-orders-separator"
                                aria-hidden={true}
                            />
                        ) : null}
                        {pastOrders.length ? (
                            <h2
                                className="past-orders-header"
                                data-testid="past-orders-header"
                            >
                                {orderHistoryContent.pastOrdersHeader}
                            </h2>
                        ) : null}

                        {pastOrders.map((order, index) => (
                            <OrderHistoryItem
                                key={order.orderNo}
                                order={order}
                                index={index}
                                orderHistoryContent={orderHistoryContent}
                                orderDetailsContent={orderDetailsContent}
                                orderStatusContent={orderStatusContent}
                                appConfig={appConfig}
                                className={className}
                                isActive={false}
                                totalOrders={orderHistoryData.totalOrders}
                                alwaysIncludeBottomBorder={true}
                            />
                        ))}
                        <div
                            className={
                                isShowMore
                                    ? `${className}-footer-container-show-more`
                                    : `${className}-footer-container`
                            }
                        >
                            {isShowMore && (
                                <TertiaryButton
                                    labelText={
                                        orderHistoryContent.showMoreOrdersButtonLabelText
                                    }
                                    ariaLabel={
                                        orderHistoryContent.showMoreOrdersButtonAriaLabel
                                    }
                                    dataTestId={`${className}-show-more`}
                                    internal={true}
                                    plusSign={true}
                                    onClick={showMoreOrders}
                                ></TertiaryButton>
                            )}

                            <div
                                className={`${className}-pagination-container`}
                            >
                                <p
                                    className={`${className}-pagination-label`}
                                    data-testid={`${className}-pagination-label`}
                                >
                                    {orderHistoryContent.paginationLabelText}
                                    {' 1-'}
                                    {displayedOrderCount}{' '}
                                    {orderHistoryContent.paginationSeparator}{' '}
                                    {orderHistoryData.totalOrders}
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {!ordersFinishedLoading ? (
                    <ActivityIndicator className={'full-height'} />
                ) : null}
            </div>
        </>
    );
};

export default OrderHistoryComponent;
