import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import VehicleTypeService, {
    findNewestVehicle,
    VehicleType,
} from '@services/vehicle-type-service/vehicle-type-service';
import {
    ProfileWithVehiclesResponse,
    VehicleDetail,
} from '@models/profile-with-vehicles';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import SecondaryNav from '@sections/secondary-nav/secondary-nav';
import useSecondaryNavContent from '@sections/secondary-nav/hook/use-secondary-nav-content';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { useLocation } from 'react-router-dom';

export interface ConnectedStatusContextProps {
    isConnected: boolean;
    hasVehicles: boolean;
    vin: string;
    firstName: string;
    setConnectedStatusContext?: (updates: ConnectedStatusContextProps) => void;
    getConnectedStatus?: (profile: ProfileWithVehiclesResponse) => void;
}

export const ConnectedStatusContext = createContext({
    isConnected: false,
    hasVehicles: false,
    vin: null,
    firstName: null,
    setConnectedStatusContext: () => null,
    getConnectedStatus: () => null,
} as any);

export const ConnectedStatusProvider = ({ children }) => {
    const [connectedStatusContext, setConnectedVehicleStatusContext] =
        useState<ConnectedStatusContextProps | null>({
            isConnected: false,
            hasVehicles: false,
            vin: null,
            firstName: null,
        });
    const location = useLocation();
    const authenticationService = new AuthenticationService();
    const appConfig = new AppConfigurationService();
    const secondaryNavContent = useSecondaryNavContent();
    const isWebview = location.search.includes('webview');
    const [showSecondaryNav, setShowSecondaryNav] = useState<boolean>(false);

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (
                isAuthenticated &&
                secondaryNavContent &&
                appConfig.currentLanguageRegionCode === 'en-us' &&
                appConfig.brand === 'ford' &&
                !isWebview
            ) {
                setShowSecondaryNav(true);
            }
        });
    }, [secondaryNavContent]);

    const setConnectedStatusContext = (
        connectedStatusContextUpdates: ConnectedStatusContextProps
    ) => {
        setConnectedVehicleStatusContext((prevState) => {
            return {
                ...prevState,
                ...connectedStatusContextUpdates,
            };
        });
    };

    const getConnectedStatus = (profile: ProfileWithVehiclesResponse): void => {
        const newestVehicle: VehicleDetail = findNewestVehicle(
            profile?.vehicles
        );
        const vehicleTypeService = new VehicleTypeService();

        if (
            appConfig.currentLanguageRegionCode !== 'en-us' ||
            appConfig.brand === 'lincoln'
        ) {
            return;
        }

        if (newestVehicle) {
            vehicleTypeService
                .requestVehicleType(
                    newestVehicle.vin,
                    appConfig.currentLanguageRegionCode,
                    appConfig.current3LetterCountryCapitalizedCode
                )
                .then((response: VehicleType) => {
                    setConnectedStatusContext({
                        isConnected: response.isConnected ?? false,
                        hasVehicles: profile?.vehicles?.length > 0,
                        vin: response?.vehicleData?.vin,
                        firstName: profile.profile.firstName,
                    });
                });
        }
    };

    const getContext = useCallback(() => {
        return {
            connectedStatusContext,
            setConnectedStatusContext,
            getConnectedStatus,
        };
    }, [connectedStatusContext]);

    return (
        <ConnectedStatusContext.Provider value={getContext()}>
            {showSecondaryNav && <SecondaryNav />}
            {children}
        </ConnectedStatusContext.Provider>
    );
};

export const useConnectedStatusContext = () =>
    useContext(ConnectedStatusContext);

export default ConnectedStatusProvider;
