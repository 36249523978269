import React, { useState } from 'react';
import { useGuestReturnLoginContent } from '@views/guest-login-views/guest-return-view/hooks/use-guest-return-login-content';
import GuestLoginComponent from '@views/guest-login-views/components/guest-login-component';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';

const GuestReturnView = () => {
    const guestReturnContent = useGuestReturnLoginContent();
    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>('');
    const guestReturn = true;
    const [errorNotificationMessage, setErrorNotificationMessage] =
        useState<string>(null);
    const [errorNotificationMessageRTE, setErrorNotificationMessageRTE] =
        useState<string>(null);

    const handleOrderDetailsSuccess = (
        orderDetails: OmsOrderDetailsResponse
    ) => {
        const hasReturnableItems = orderDetails?.shippingOrderLine?.some(
            (order) => order.isStorefrontReturnable === 'Y'
        );

        if (hasReturnableItems) {
            if (orderDetails.orderNo == orderNumber) {
                navigate(findPathByAlias('CreateReturnView'), {
                    state: {
                        orderDetails,
                        guestReturn,
                    },
                });
            } else if (
                orderDetails?.shippingOrderLine[0].orderNo == orderNumber
            ) {
                navigate(findPathByAlias('CreateReturnView'), {
                    state: {
                        orderDetails: {
                            ...orderDetails,
                            orderNo: orderDetails?.shippingOrderLine[0].orderNo,
                        },
                        guestReturn,
                    },
                });
            }
        } else {
            setErrorNotificationMessage(
                guestReturnContent.noReturnableItemsErrorMessage
            );
            setErrorNotificationMessageRTE(
                guestReturnContent.noReturnableItemsErrorMessageRTEContent
            );
        }
    };

    return (
        <>
            <GuestLoginComponent
                guestLoginContent={guestReturnContent}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                orderNumber={orderNumber}
                setOrderNumber={setOrderNumber}
                errorNotificationMessage={errorNotificationMessage}
                setErrorNotificationMessage={setErrorNotificationMessage}
                errorNotificationMessageRTE={errorNotificationMessageRTE}
                setErrorNotificationMessageRTE={setErrorNotificationMessageRTE}
                handleOrderDetailsSuccess={handleOrderDetailsSuccess}
                rteField={true}
                noNotifTimer={true}
            />
        </>
    );
};

export default GuestReturnView;
