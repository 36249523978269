import React, { useContext, useEffect, useState } from 'react';
import { usePreferredDealerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-content';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import PreferredDealerComponent from '@views/preferred-dealer-view/components/preferred-dealer-component/preferred-dealer-component';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { findPathByAlias } from '@routes/routesList';
import { ScrollUtil } from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import HttpService from '@services/http-service/http-service';
import PreferredDealerService from '@services/preferred-dealer-service/preferred-dealer-service';
import ProfileService from '@services/profile-service/profile-service';
import BingService from '@services/bing-service/bing-service';
import { CacheService } from '@services/cache-service/cache-service';
import { VehicleDetail } from '@models/profile-with-vehicles';
import { SearchBar } from '@/components/sections';
import { useWindowSize } from '@hooks/use-window-size';
import {
    EU_PREFERRED_DEALER_ID_PREFIXES,
    PREFERRED_DEALER_CONSENT_KEY,
    VEHICLE_CARD_STORAGE_KEY,
} from '@constants';
import ServerContext from '@contexts/serverContext';
import { useAnalytics } from '@hooks/use-analytics';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import GoogleMapService from '@services/google-map-service/google-map-service';

import './preferred-dealer-view.scss';
import ReturnButton from '@sections/return-button/return-button';
import { scriptService } from '@services/script-service/script-service';
import { useDealerConsentContent } from '@/components/sections/preferred-dealer-consent/hooks/use-dealer-consent';
import { useModalContext } from '@/contexts/modalContext';
import PreferredDealerConsentContent from '@/components/sections/preferred-dealer-consent/preferred-dealer-consent-modal';
import {
    Device,
    PrivacyPreferenceRequest,
    PrivacyPreferencesRequestData,
} from '@/models/preferred-dealer-consent';
import PreferredDealerConsentService from '@/services/preferred-dealer-consent-service/preferred-dealer-consent-service';
import { useConnectedStatusContext } from '@contexts/connectedStatusContext';

export interface PreferredDealerItem {
    vin: string;
    model: string;
    preferredDealerCode: string;
    modelYear?: string;
    modelName?: string;
    preferredDealer?: string;
}

const PreferredDealerView = () => {
    const navigate = useNavigate();
    const { setNotificationContext } = useNotificationContext();
    const [fireAnalytics] = useAnalytics();
    const httpService = HttpService;
    const googleMapService = new GoogleMapService();
    const appConfig = new AppConfigurationService();
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const isFord = appConfig.brand === 'ford';
    const isLincoln = appConfig.brand === 'lincoln';
    const bingService = new BingService(httpService);
    const [profileVehicles, setProfileVehicles] =
        useState<VehicleDetail[]>(null);
    const [vinVehicleData, setVinVehicleData] = useState<any>(null);
    const size = useWindowSize();
    const TABLET_MOBILE_BREAKPOINT = 768;
    const isDesktop = size.width > TABLET_MOBILE_BREAKPOINT;
    const returnButtonContent = useReturnButtonContent();
    const preferredDealerContent = usePreferredDealerContent();
    const authenticationService = new AuthenticationService();
    const profileService = new ProfileService();
    const [showPreferredDealerComponent, setShowPreferredDealerComponent] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [preferredDealerData, setPreferredDealerData] =
        useState<PreferredDealerItem>(null);
    const [instantPreferredDealer, setInstantPreferredDealer] =
        useState<string>(null);
    const [hasCheckedSessionStorageForVin, setHasCheckedSessionStorageForVin] =
        useState<boolean>(false);
    const dashboardPathWithPreferredDealerParam: string =
        findPathByAlias('AccountDashboardView') + '?preferredDealer=true';
    const [defaultDealerLocation, setDefaultDealerLocation] =
        useState<string>(null);
    const isWebview =
        new URLSearchParams(useLocation().search).get('webview') || undefined;
    const context = useContext(ServerContext);
    const webviewData = context.webviewData;
    const [googleMapsScriptLoaded, setGoogleMapsScriptLoaded] =
        useState<boolean>(false);
    const preferredDealerConsentService = new PreferredDealerConsentService(
        httpService
    );
    const dealerConsentContent = useDealerConsentContent();
    const { setContext, resetContext } = useModalContext();
    const [userGuid, setUserGuid] = useState<string>(null);
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const [fullProfileLite, setFullProfileLite] = useState(null);
    const { getConnectedStatus } = useConnectedStatusContext();

    const searchEuByDealerCode = (dealerCode: string) => {
        googleMapService.searchDealersByProperties(
            1,
            (response) => {
                response[0]
                    ? setDefaultDealerLocation(response[0]?.Locality)
                    : setDefaultDealerLocation('');
            },
            {
                DealerID: dealerCode,
            }
        );
    };

    const setDealerConsent = (dealerConsent: any, consentStatus: boolean) => {
        const privacyPreferencesRequestData: PrivacyPreferencesRequestData =
            {} as PrivacyPreferencesRequestData;
        privacyPreferencesRequestData.brandCode = 'F';
        privacyPreferencesRequestData.countryCode = appConfig
            .get3LetterCountryCode()
            .toUpperCase();
        privacyPreferencesRequestData.guid = userGuid;
        privacyPreferencesRequestData.privacyPreferences = [];
        const privacyPreferenceRequest: PrivacyPreferenceRequest =
            {} as PrivacyPreferenceRequest;
        privacyPreferenceRequest.consentName = PREFERRED_DEALER_CONSENT_KEY;
        privacyPreferenceRequest.statusCode = consentStatus ? 'Y' : 'N';
        privacyPreferenceRequest.preferredDealerId =
            dealerConsent.preferredDealer;
        privacyPreferenceRequest.devices = [];
        const device: Device = {} as Device;
        device.deviceType = 'VIN';
        device.deviceValue = dealerConsent.vin;
        privacyPreferenceRequest.devices.push(device);
        privacyPreferencesRequestData.privacyPreferences.push(
            privacyPreferenceRequest
        );
        preferredDealerConsentService
            .updatePreferredDealerConsent(privacyPreferencesRequestData)
            .then((response) => {
                if (response.error == null) {
                    isFord && setNotificationStatus(NotificationType.Success);
                    isLincoln &&
                        setNotificationContext(
                            NotificationType.DealerConsentSuccess,
                            false
                        );
                }
                resetContext();
            })
            .catch((err) => {
                console.error(err);
                resetContext();
            });
    };

    const createDealerConsentModalProps = (vinsWithoutConsent?: any) => {
        return {
            modalType: {
                name: 'preferred-dealer-consent-modal',
                primaryButtonLabel: dealerConsentContent.acceptButtonLabelText,
                secondaryButtonLabel:
                    dealerConsentContent.declineButtonLabelText,
                onPrimaryButtonClick: () => {
                    const consentSelection = 'accept';
                    fireAnalytics(
                        'preferredDealerDealerConsentOnclickEvent',
                        '',
                        { consentSelection }
                    );
                    setDealerConsent(vinsWithoutConsent, true);
                },
                onSecondaryButtonClick: () => {
                    const consentSelection = 'decline';
                    fireAnalytics(
                        'preferredDealerDealerConsentOnclickEvent',
                        '',
                        { consentSelection }
                    );
                    setDealerConsent(vinsWithoutConsent, false);
                },
                preventClose: true,
                children: (
                    <PreferredDealerConsentContent
                        preferredDealerConsentContent={dealerConsentContent}
                    />
                ),
            },
        };
    };

    const updatePreferredDealer = async ({
        vin,
        preferredDealerCode,
    }: PreferredDealerItem) => {
        const httpService = HttpService;
        const preferredDealerService = new PreferredDealerService(httpService);
        setInstantPreferredDealer(preferredDealerCode);
        await preferredDealerService
            .updatePreferredDealer(vin, preferredDealerCode)
            .then((response) => {
                if (response.status == 200 && response.httpStatus == 200) {
                    if (dealerConsentContent) {
                        const dealerConsentModalProps =
                            createDealerConsentModalProps({
                                vin: vin,
                                preferredDealer: preferredDealerCode,
                            });

                        setContext(dealerConsentModalProps);
                    }
                }
            });
        sessionStorage.setItem(
            VEHICLE_CARD_STORAGE_KEY,
            JSON.stringify({
                vin,
            })
        );
        if (isEu) {
            searchEuByDealerCode(preferredDealerCode);
        } else {
            bingService
                .getDealerInfoById(preferredDealerCode)
                .then((response) => {
                    if (response[0].state) {
                        setDefaultDealerLocation(
                            `${response[0].city}, ${response[0].state}`
                        );
                    }
                });
        }

        new CacheService().evictProfileLiteCache();
        new CacheService().evictBingServiceCache();
    };

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                const selectedVinFromStorage = JSON.parse(
                    sessionStorage.getItem(VEHICLE_CARD_STORAGE_KEY)
                );
                if (!selectedVinFromStorage) {
                    navigate(dashboardPathWithPreferredDealerParam);
                }
                profileService.requestLite().then((profile) => {
                    if (profile) {
                        setFullProfileLite(profile);
                        setUserGuid(profile.profile.userGuid.toUpperCase());
                        // check for vehicle in user's garage
                        const matchingVehicle = profile.vehicles.find(
                            (vehicleData) =>
                                vehicleData.vin === selectedVinFromStorage.vin
                        );
                        matchingVehicle && setVinVehicleData(matchingVehicle);
                        setProfileVehicles(profile.vehicles);
                        setHasCheckedSessionStorageForVin(true);
                    }
                });
            } else if (isWebview && webviewData.vin) {
                profileService.requestLite().then((profile) => {
                    if (profile) {
                        setUserGuid(profile.profile.userGuid.toUpperCase());
                        // check for vehicle in user's garage
                        const matchingVehicle = profile.vehicles.find(
                            (vehicleData) => vehicleData.vin === webviewData.vin
                        );
                        matchingVehicle && setVinVehicleData(matchingVehicle);
                        setInstantPreferredDealer(
                            matchingVehicle?.preferredDealer
                        );
                        setProfileVehicles(profile.vehicles);
                    }
                });
            } else {
                authenticationService.updateState(
                    findPathByAlias('PreferredDealerView')
                );
                authenticationService.login();
            }
        });

        isEu &&
            scriptService.loadGoogleMapsScript(() => {
                setGoogleMapsScriptLoaded(true);
            });
        new ScrollUtil().scrollPageToTop();
    }, []);

    useEffect(() => {
        if (hasCheckedSessionStorageForVin && profileVehicles) {
            const userHasVehicle = !!profileVehicles.find(
                (vehicle: VehicleDetail) =>
                    vehicle.vin === preferredDealerData?.vin
            );
            if (!userHasVehicle) {
                navigate(dashboardPathWithPreferredDealerParam);
            }
        }
    }, [hasCheckedSessionStorageForVin, profileVehicles, preferredDealerData]);

    useEffect(() => {
        if (vinVehicleData) {
            setPreferredDealerData(vinVehicleData);
            setInstantPreferredDealer(vinVehicleData?.preferredDealer);
            setIsLoading(false);
            setShowPreferredDealerComponent(true);
        }
    }, [vinVehicleData]);

    useEffect(() => {
        if (
            preferredDealerData?.vin &&
            (preferredDealerData?.preferredDealerCode ||
                preferredDealerData?.preferredDealer)
        ) {
            if (isEu) {
                searchEuByDealerCode(
                    EU_PREFERRED_DEALER_ID_PREFIXES.find(
                        (country) =>
                            country.country === appConfig.currentCountryCode
                    ).prefix +
                        (preferredDealerData.preferredDealer ||
                            preferredDealerData?.preferredDealerCode)
                );
            } else {
                bingService
                    .getDealerInfoById(
                        preferredDealerData.preferredDealerCode ||
                            preferredDealerData.preferredDealer
                    )
                    .then((response) => {
                        if (response[0]?.state && !isEu) {
                            setDefaultDealerLocation(
                                `${response[0].city}, ${response[0].state}`
                            );
                        }
                    });
            }
        } else {
            setDefaultDealerLocation('');
        }
    }, [preferredDealerData]);

    useEffect(() => {
        if (fullProfileLite) {
            getConnectedStatus(fullProfileLite);
        }
    }, [fullProfileLite]);

    const vehicleYearAndModel: string = preferredDealerData
        ? preferredDealerData.modelYear + ' ' + preferredDealerData.modelName
        : null;

    return (
        <>
            <div
                className="preferred-dealer__view"
                data-testid="preferred-dealer-view"
            >
                {returnButtonContent && (
                    <ReturnButton
                        returnButtonContent={returnButtonContent}
                        dataTestId="preferred-dealer-back-button"
                        onClick={() =>
                            fireAnalytics(
                                'backButtonPreferredDealerOnclickEvent'
                            )
                        }
                        fromPreferredDealerPage
                    ></ReturnButton>
                )}

                {notificationStatus && (
                    <div
                        className="preferred-dealer__notification-container"
                        data-testid="preferred-dealer__notification-container"
                    >
                        <Notification
                            status={notificationStatus}
                            mainCopy={
                                dealerConsentContent.notificationBannerHeaderText
                            }
                            subCopy={
                                dealerConsentContent.notificationBannerBodyText
                            }
                            rteField={true}
                            hideBorder={true}
                            hideAfterTimeout={true}
                            onHideNotification={() => {
                                setNotificationStatus(null);
                            }}
                        />
                    </div>
                )}

                {showPreferredDealerComponent &&
                preferredDealerContent &&
                !isLoading &&
                defaultDealerLocation !== null ? (
                    <PreferredDealerComponent
                        appConfig={appConfig}
                        preferredDealerContent={preferredDealerContent}
                        vin={vinVehicleData?.vin || webviewData?.vin}
                        selectedModel={
                            vinVehicleData?.model || vehicleYearAndModel
                        }
                        selectedPreferredDealer={
                            vinVehicleData?.preferredDealerCode ||
                            vinVehicleData?.preferredDealer
                        }
                        updatePreferredDealer={updatePreferredDealer}
                        instantPreferredDealer={
                            instantPreferredDealer ||
                            vinVehicleData?.preferredDealerCode ||
                            vinVehicleData?.preferredDealer
                        }
                        setInstantPreferredDealer={setInstantPreferredDealer}
                        defaultDealerLocation={
                            defaultDealerLocation ? defaultDealerLocation : null
                        }
                        isMobile={!isDesktop}
                        googleMapsScriptLoaded={googleMapsScriptLoaded}
                    />
                ) : null}

                {isLoading && <ActivityIndicator className="full-height" />}
            </div>
            {isDesktop && (
                <hr className="hr-line mB0 preferred-dealer__horizontal-rule" />
            )}
            <div
                className="preferred-dealer__self-help-wrapper"
                data-testid="search-bar-in-preferred-dealer-view"
            >
                <SearchBar />
            </div>
        </>
    );
};

export default PreferredDealerView;
