import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TertiaryButton from '@common/tertiary-button/tertiary-button';
import { findPathByAlias } from '@routes/routesList';
import { LinkTargetValues } from '@constants';
import Toggle from '@common/toggle/toggle';
import { Checkbox, Option } from '@common/form-fields/form-fields';
import {
    CommunicationCenterContent,
    PermissionOption,
    PreferenceOption,
} from '@models/communication-center';
import { PrimaryButton, SecondaryButton } from '@common/index';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import UserCommunicationPreferenceService, {
    PrivacyPreference,
} from '@services/communication-preference-service/communication-preference-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { CacheService } from '@services/cache-service/cache-service';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import { NavLink } from 'react-router-dom';

export interface PermissionEditProps {
    profileData: any;
    getProfileData: () => Promise<ProfileWithVehiclesResponse>;
    isEmailPermission: boolean;
    communicationCenterContent: CommunicationCenterContent;
    permissionOption: PermissionOption;
    preferenceOptions: PreferenceOption[];
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    setNotificationStatus: Dispatch<SetStateAction<NotificationType>>;
    scrollUtil: ScrollUtil;
    isMobile?: boolean;
    isLincoln?: boolean;
}
const PermissionEdit = (props: PermissionEditProps) => {
    const {
        profileData,
        getProfileData,
        isEmailPermission,
        communicationCenterContent,
        permissionOption,
        preferenceOptions,
        setIsEdit,
        setNotificationStatus,
        scrollUtil,
        isMobile,
        isLincoln,
    } = props;
    const userCommunicationPreferenceService =
        new UserCommunicationPreferenceService();
    const cacheService = new CacheService();
    const { setNotificationContext } = useNotificationContext();

    const sanitizeId = (value: string): string => {
        // example output: vehicle-launch-announcements-purchase-offers-incentives
        return value
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .split('-')
            .filter(Boolean)
            .join('-'); // Ensure only one hyphen between words
    };

    const getIsChecked = (preferenceName: string): boolean => {
        const preference = profileData.privacyPreferences.find(
            (pref) => pref.preferenceName === preferenceName
        );
        return preference
            ? [1, '1'].includes(preference.preferenceCode)
            : false;
    };

    const [isToggleChecked, setIsToggleChecked] = useState<boolean>(
        getIsChecked('EmailPermission')
    );

    const [preferenceValues, setPreferenceValues] = useState<Option[]>(
        preferenceOptions.map((option) => ({
            name: option.apiVariable,
            value: option.apiVariable,
            id: `${sanitizeId(option.apiVariable)}-checkbox`,
            displayName: option.optionLabel,
            isChecked: !isToggleChecked
                ? false
                : getIsChecked(option.apiVariable),
            showTooltip: false,
        }))
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const convertPreferencesToServiceFormat = (
        preferenceValues: Option[],
        isToggleChecked: boolean
    ): PrivacyPreference[] => {
        const emailPermission: PrivacyPreference = {
            preferenceType: 'EmailPermission',
            preferenceCode: isToggleChecked ? 1 : 0,
        };

        const otherPreferences: PrivacyPreference[] = preferenceValues.map(
            (option) => ({
                preferenceType: option.value,
                preferenceCode: option.isChecked ? 1 : 0,
            })
        );

        return [emailPermission, ...otherPreferences];
    };

    const checkAllBoxes = (): void => {
        const updatedPreferenceValues: Option[] = preferenceValues.map(
            (option) => {
                option.isChecked = true;
                return option;
            }
        );
        setPreferenceValues(updatedPreferenceValues);
    };
    const uncheckAllBoxes = (): void => {
        const updatedPreferenceValues: Option[] = preferenceValues.map(
            (option) => {
                option.isChecked = false;
                return option;
            }
        );
        setPreferenceValues(updatedPreferenceValues);
    };

    const handleToggleChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (e.target.checked) {
            checkAllBoxes();
        } else {
            uncheckAllBoxes();
        }
        setIsToggleChecked(e.target.checked);
    };

    const updatePrivacyPreferences = async () => {
        cacheService.evictProfileServiceCache();
        setNotificationStatus(null);
        setNotificationContext(NotificationType.None, false);
        setIsLoading(true);
        const isUpdateSuccessful =
            await userCommunicationPreferenceService.updatePrivacyPreferencesV3(
                convertPreferencesToServiceFormat(
                    preferenceValues,
                    isToggleChecked
                )
            );
        if (isUpdateSuccessful) {
            await getProfileData();
            setNotification(
                NotificationType.Success,
                communicationCenterContent.notificationBannerSuccess
            );
        } else {
            setNotification(
                NotificationType.Error,
                communicationCenterContent.notificationBannerError
            );
        }
        scrollUtil.scrollPageToTop();
        setIsLoading(false);
        setIsEdit(false);
    };

    const setNotification = (type: NotificationType, message: string) => {
        if (isLincoln) {
            setNotificationContext(type, false, message);
        } else {
            setNotificationStatus(type);
        }
    };

    const handleCheckboxChange = (updatedOptions) => {
        setPreferenceValues(updatedOptions);

        const isChecked = updatedOptions.some((option) => option.isChecked);
        setIsToggleChecked(isChecked);
    };

    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}

            <div
                className="permission-edit__container"
                data-testid="permission-edit-container"
            >
                {profileData && isEmailPermission && (
                    <div className="your-account-info__section">
                        <h5 className="your-account-info__heading">
                            {communicationCenterContent?.yourAccountInfo}
                        </h5>

                        <div className="your-account-info__grid">
                            <div className="your-account-info__row">
                                <h6 className="name">
                                    {profileData?.firstName}{' '}
                                    {profileData?.lastName}
                                </h6>
                                {isLincoln ? (
                                    <NavLink
                                        className={'update-button-lincoln'}
                                        to={findPathByAlias(
                                            'AccountSettingsView'
                                        )}
                                        aria-label={
                                            communicationCenterContent?.updateButtonAriaLabel
                                        }
                                    >
                                        {
                                            communicationCenterContent?.updateButton
                                        }
                                    </NavLink>
                                ) : (
                                    <TertiaryButton
                                        internal={true}
                                        labelText={
                                            communicationCenterContent?.updateButton
                                        }
                                        link={findPathByAlias(
                                            'AccountSettingsView'
                                        )}
                                        linkTarget={LinkTargetValues.SELF}
                                        ariaLabel={
                                            communicationCenterContent?.updateButtonAriaLabel
                                        }
                                    />
                                )}
                            </div>
                            <div className="your-account-info__row">
                                <h6 className="email">{profileData?.email}</h6>
                                {isLincoln ? (
                                    <NavLink
                                        className={'update-button-lincoln'}
                                        to={findPathByAlias(
                                            'SignInCredentialsView'
                                        )}
                                        aria-label={
                                            communicationCenterContent?.updateButtonAriaLabel
                                        }
                                    >
                                        {
                                            communicationCenterContent?.updateButton
                                        }
                                    </NavLink>
                                ) : (
                                    <TertiaryButton
                                        internal={true}
                                        labelText={
                                            communicationCenterContent?.updateButton
                                        }
                                        link={findPathByAlias(
                                            'SignInCredentialsView'
                                        )}
                                        linkTarget={LinkTargetValues.SELF}
                                        ariaLabel={
                                            communicationCenterContent?.updateButtonAriaLabel
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="channel__content">
                    <h4 className="channel__header">
                        {permissionOption.toggleLabel}
                    </h4>

                    <Toggle
                        isToggleChecked={isToggleChecked}
                        handleChange={handleToggleChange}
                        isLincoln={isLincoln}
                    />

                    <div className="channel-types__container">
                        <p className="channel-types__heading">
                            {permissionOption.preferenceTypesLabel}
                        </p>

                        {preferenceOptions?.length > 0 && (
                            <Checkbox
                                options={preferenceValues}
                                setOptions={setPreferenceValues}
                                handleChange={handleCheckboxChange}
                                labelClassName="communication-center__checkbox-label"
                                hasTooltip={true}
                                tooltipPreferenceOptions={preferenceOptions}
                                isMobile={isMobile}
                                isLincoln={isLincoln}
                                tooltipAriaLabel={
                                    communicationCenterContent?.tooltipAriaLabel
                                }
                            />
                        )}

                        <div className="buttons-container">
                            {isLincoln ? (
                                <button
                                    aria-label={
                                        communicationCenterContent?.cancelButtonAriaLabel
                                    }
                                    className="fmc-button permission__cancel-button-lincoln"
                                    onClick={() => {
                                        setIsEdit(false);
                                        scrollUtil.scrollPageToTop();
                                    }}
                                    data-testid="permission-cancel-button"
                                >
                                    {communicationCenterContent?.cancelButton}
                                    <div className="lincoln-underline-button-overlay"></div>
                                </button>
                            ) : (
                                <SecondaryButton
                                    aria-label={
                                        communicationCenterContent?.cancelButtonAriaLabel
                                    }
                                    className="permission__cancel-button"
                                    onClick={() => {
                                        setIsEdit(false);
                                        scrollUtil.scrollPageToTop();
                                    }}
                                    dataTestId="permission-cancel-button"
                                >
                                    {communicationCenterContent?.cancelButton}
                                </SecondaryButton>
                            )}

                            <PrimaryButton
                                ariaLabel={
                                    communicationCenterContent?.saveButtonAriaLabel
                                }
                                className="permission__save-button"
                                testId="permission-save-button"
                                onClick={updatePrivacyPreferences}
                                hideChevron={isLincoln}
                            >
                                {communicationCenterContent?.saveButton}
                            </PrimaryButton>
                        </div>

                        <p
                            className="disclaimer"
                            dangerouslySetInnerHTML={{
                                __html: communicationCenterContent?.disclosureText,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PermissionEdit;
