import React, { useEffect } from 'react';
import { OrderHistoryContent } from '@views/order-history-view/hooks/use-order-history-content';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import {
    OmsOrderHistoryResponse,
    OrderHistoryOrder,
} from '@services/oms-service/oms-service';
import OrderHistoryVehicleItem from '@views/order-history-view/components/order-history-drawer/components/order-history-vehicle-item/order-history-vehicle-item';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { SecondaryButton } from '@common/index';
import OrderHistoryItem from '@views/order-history-view/components/order-history-item/order-history-item';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import serverSideService from '@services/server-side-service/server-side-service';

import './order-history-drawer.scss';
import { LinkTargetValues } from '@constants';
import { useSideDrawerContext } from '@contexts/sideDrawerContext';
import { findPathByAlias } from '@routes/routesList';

export interface OrderHistoryDrawerProps {
    appConfig: any;
    vehicleOrders: OrderedVehiclesItem[];
    orderHistoryData: OmsOrderHistoryResponse;
    orderHistoryContent: OrderHistoryContent;
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    vehicleCarouselContent: VehicleCarouselContent;
}

const OrderHistoryDrawer = (props: OrderHistoryDrawerProps) => {
    const {
        appConfig,
        orderDetailsContent,
        orderHistoryContent,
        orderStatusContent,
        vehicleOrders,
        orderHistoryData,
        vehicleCarouselContent,
    } = props;

    const { resetContext } = useSideDrawerContext();

    const accessoriesOrders: OrderHistoryOrder[] =
        orderHistoryData?.orders.slice(0, 5);

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            const closeButton: HTMLButtonElement = document.querySelector(
                '#order-history-drawer-close-button'
            );

            closeButton?.focus();
        }
    }, []);

    return (
        <div
            className="order-history-drawer"
            data-testid="order-history-drawer"
        >
            <h1
                className="order-history-drawer__header"
                data-testid="order-history-drawer-header"
            >
                {vehicleCarouselContent?.orderHistoryDrawerHeader}
            </h1>

            {vehicleOrders?.length ? (
                <div
                    className="order-history-drawer__subheader"
                    data-testid="order-history-drawer-vehicles-section"
                >
                    <h2
                        className="order-history-drawer-subheader-vehicles"
                        data-testid="order-history-drawer-subheader-vehicles"
                    >
                        {
                            vehicleCarouselContent?.orderHistoryDrawerVehiclesSubheader
                        }
                    </h2>

                    {vehicleOrders.map((order) => (
                        <div className="vehicle-item-container" key={order.id}>
                            <OrderHistoryVehicleItem
                                order={order}
                                vehicleCarouselContent={vehicleCarouselContent}
                                current3LetterCountryCapitalizedCode={
                                    appConfig.current3LetterCountryCapitalizedCode
                                }
                                appConfig={appConfig}
                            />
                        </div>
                    ))}

                    <SecondaryButton
                        internal
                        aria-label={
                            vehicleCarouselContent?.viewAllVehicleOrdersButtonAriaLabel
                        }
                        className="view-vehicle-orders-button"
                        link={
                            vehicleCarouselContent?.viewAllVehicleOrdersButtonCtaHref
                        }
                        linkTarget={LinkTargetValues.BLANK}
                    >
                        {
                            vehicleCarouselContent?.viewAllVehicleOrdersButtonLabelText
                        }
                    </SecondaryButton>

                    {accessoriesOrders?.length ? (
                        <hr className="order-history-drawer__divider" />
                    ) : null}
                </div>
            ) : null}

            {accessoriesOrders?.length ? (
                <div
                    className="order-history-drawer__subheader"
                    data-testid="order-history-drawer-accessories-section"
                >
                    <h2
                        className="order-history-drawer-subheader-accessories"
                        data-testid="order-history-drawer-subheader-accessories"
                    >
                        {orderHistoryContent?.sideDrawerAccessoriesSubHeader}
                    </h2>

                    {accessoriesOrders.map((order, index) => (
                        <div
                            className={`accessories-item-container ${
                                index === accessoriesOrders.length - 1
                                    ? 'last-item'
                                    : ''
                            }`}
                            data-testid="accessories-item-container"
                            key={order.orderNo}
                        >
                            <OrderHistoryItem
                                order={order}
                                index={index}
                                orderHistoryContent={orderHistoryContent}
                                orderDetailsContent={orderDetailsContent}
                                orderStatusContent={orderStatusContent}
                                appConfig={appConfig}
                                className="order-history-drawer"
                                isActive={true}
                                totalOrders={orderHistoryData.totalOrders}
                                fromDrawer
                                alwaysIncludeBottomBorder={false}
                            />
                        </div>
                    ))}

                    <SecondaryButton
                        internal
                        aria-label={
                            orderHistoryContent?.viewAllAccessoriesOrdersButtonAriaLabelText
                        }
                        className="view-accessories-orders-button"
                        link={findPathByAlias('OrderHistoryView')}
                        onClick={() => resetContext()}
                    >
                        {
                            orderHistoryContent?.viewAllAccessoriesOrdersButtonLabelText
                        }
                    </SecondaryButton>
                </div>
            ) : null}
        </div>
    );
};

export default OrderHistoryDrawer;
