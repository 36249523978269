import HttpService from '@services/http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';

export interface Item {
    itemId: string;
    itemShortDesc: string;
    itemSubType: string;
    imageID: string;
    unitOfMeasure: string;
}

interface Items {
    itemID: string;
    itemShortDesc: string;
}

interface OrderLines {
    orderLine: OrderLine[];
    totalNumberOfRecords: string;
}

export interface OrderHistoryOrder {
    status: string;
    orderNo: string;
    orderDate: string;
    orderHeaderKey: string;
    originalTotalAmount: string;
    orderLines: OrderLines;
    isActive: string;
}

export interface OmsOrderHistoryResponse {
    totalOrders: string;
    totalNumberOfPages: string;
    pageSize: string;
    pageNumber: string;
    isLastPage: string;
    orders: OrderHistoryOrder[];
}

export interface OmsOrderDetailsResponse {
    orderNo: string;
    orderDate: string;
    orderStatus: string;
    // orderLine: OrderLineDetails[];
    pickUpOrderLine: OrderLineDetails[];
    shippingOrderLine: OrderLineDetails[];
    customerEmailId: string;
    personInfoBillTo: PersonInfoBillTo;
    personInfoShipTo: PersonInfoShipTo;
    personInfoPickup: PersonInfoPickup;
    overallTotals: OverallTotals;
    totalNumberOfRecords: string;
    orderHeaderKey: string;
}

export interface OmsCreateReturnResponse {
    orderNo: string;
    orderLine: CreateReturnOrderLine[];
    orderHeaderKey: string;
    priceInfo: CreateReturnPriceInfo;
}

export interface CreateReturnPriceInfo {
    totalAmount: string;
}

export interface CreateReturnOrderLine {
    orderedQty: string;
    orderLineKey: string;
    item: CreateReturnItem;
    itemDetails: ItemDetails;
    lineOverallTotals: LineOverallTotals;
    derivedFromOrder: DeriviedFromOrder;
}

export interface CreateReturnItem {
    unitOfMeasure: string;
    itemId: string;
    itemShortDesc: string;
}

export interface DeriviedFromOrder {
    entryType: string;
    orderNo: string;
    orderHeaderKey: string;
}

export interface Extn {
    extnProductURL: string;
    extnOrderProcessedDate: string;
    extnItemSubType: string;
}

export interface ItemDetails {
    primaryInformation: PrimaryInformation;
    extn: Extn;
    itemId: string;
}

export interface PrimaryInformation {
    description: string;
    returnWindow?: string;
    imageID: string;
}

export interface OverallTotals {
    grandCharges: string;
    grandTax: string;
    grandTotal: string;
    hdrCharges: string;
    lineSubTotal: string;
    grandDiscount: string;
}

export interface PriceInfo {
    Currency: string;
    TotalAmount: string;
}

export interface PersonInfoBillTo {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    mobilePhone: string;
    otherPhone: string;
}

export interface PersonInfoShipTo {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    mobilePhone: string;
    otherPhone: string;
}

export interface PersonInfoPickup {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    mobilePhone: string;
    otherPhone: string;
}

export interface OrderLine {
    status: string;
    item: Items;
    itemDetails: ItemDetails;
    deliveryMethod: string;
    originalOrderedQty: string;
    orderNo: string;
    orderHeaderKey: string;
}

export interface OrderLineDetails {
    status: string;
    item: Item;
    lineOverallTotals: LineOverallTotals;
    originalOrderedQty: string;
    returnableQty: string;
    isStorefrontReturnable: string;
    serviceAssociations: ServiceAssociations;
    orderLineKey: string;
    deliveryMethod: string;
    orderNo: string;
    orderHeaderKey: string;
    shipNode?: string;
}

export interface LineOverallTotals {
    charges: string;
    discount: string;
    extendedPrice: string;
    lineTotal: string;
    lineTotalWithoutTax: string;
    tax: string;
    unitPrice: string;
}
export interface ServiceAssociations {
    serviceAssociation: ServiceAssociation[];
}
export interface ServiceAssociation {
    serviceLine: ServiceLine;
    orderHeaderKey: string;
    productLine: ServiceLine;
}
export interface ServiceLine {
    primeLineNo: string;
    orderLineKey: string;
}

export interface OmsReturnLabelResponse {
    returnTrackingNo: string;
    returnLabel: string;
}

export interface OmsPackingSlipResponse {
    base64ByteCode: string;
}

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class OmsService {
    private httpService: any;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getOrderHistory(
        customerEmailId: string,
        pageNumber: string,
        pageSize: string
    ): Promise<OmsOrderHistoryResponse> {
        const url: string = DSL_BASE_URL + DSL_API_PATH.OMS_ORDER_HISTORY;

        let response: any;
        try {
            response = await this.httpService.get(url, false, {
                headers: {
                    ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                    customerEMailID: customerEmailId,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,

                params: {
                    enterpriseCode: 'FordUS',
                    entryType: 'ACCESSORIES',
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                },
            });
        } catch (error) {
            return null;
        }
        return response?.data;
    }

    public async getOrderDetails(
        customerEmailId: string,
        orderNumber: string
    ): Promise<OmsOrderDetailsResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.OMS_ORDER_DETAILS;

        let response: any;
        try {
            response = await this.httpService.get(url, false, {
                headers: {
                    customerEMailID: customerEmailId,
                    orderNumber: orderNumber,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
                params: {
                    enterpriseCode: 'FordUS',
                },
            });
        } catch (error) {
            return null;
        }
        return response?.data;
    }

    public async getCompleteOrderDetails(
        customerEmailId: string,
        orderNumber: string
    ): Promise<OmsOrderDetailsResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.OMS_ORDER_DETAILS_AUTH;

        let response: any;
        try {
            response = await this.httpService.get(url, false, {
                headers: {
                    ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                    customerEMailID: customerEmailId,
                    orderNumber: orderNumber,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
                params: {
                    enterpriseCode: 'FordUS',
                },
            });
        } catch (error) {
            return null;
        }
        return response?.data;
    }

    public async getReturnLabel(
        orderHeaderKey: string,
        orderLineKey: string
    ): Promise<OmsReturnLabelResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.OMS_RETURN_LABEL;

        let response: any;
        try {
            response = await this.httpService.post(
                url,
                {
                    orderLine: [
                        {
                            orderLineKey,
                        },
                    ],
                },
                {
                    headers: {
                        orderHeaderKey,
                    },
                    adapter: httpAdapter,
                    proxy: false,
                    httpAgent: proxyAgent,
                }
            );
        } catch (error) {
            return null;
        }
        return response?.data;
    }

    public async getPackingSlip(
        orderHeaderKey: string
    ): Promise<OmsPackingSlipResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.OMS_PACKING_SLIP;

        let response: any;
        try {
            response = await this.httpService.get(url, false, {
                headers: {
                    orderHeaderKey,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            });
        } catch (error) {
            return null;
        }
        return response?.data;
    }

    public async createReturn(
        requestBody: any,
        orderHeaderKey: string,
        customerEmailId: string
    ): Promise<OmsCreateReturnResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.OMS_CREATE_RETURN;
        const body = JSON.stringify(requestBody);
        let response: any;
        try {
            response = await this.httpService.post(url, body, {
                headers: {
                    Orderheaderkey: orderHeaderKey,
                    'Content-Type': 'application/json',
                    customerEmailID: customerEmailId,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
                params: {
                    enterpriseCode: 'FordUS',
                },
            });
            if (response?.data && response?.data?.Error) return null;
        } catch (error) {
            return null;
        }
        return response?.data;
    }
}
