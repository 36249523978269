import serverSideService from '@services/server-side-service/server-side-service';

export const interceptCrossDomainLinkBehavior = (
    domain: string,
    pathname: string
): (() => void | undefined) => {
    const isWalletOrSSP: boolean =
        pathname.includes('wallet') || pathname.includes('connected-services');
    const isAustraliaOrNewZealand: boolean =
        domain.includes('com.au') || domain.includes('co.nz');

    if (
        serverSideService.isClientSide() &&
        isWalletOrSSP &&
        isAustraliaOrNewZealand
    ) {
        const links = document.querySelectorAll('a');

        if (links.length) {
            const interceptLink = (link: HTMLAnchorElement) => {
                const linkShouldBeIntercepted = !link.href.includes(domain);

                if (linkShouldBeIntercepted) {
                    link.onclick = (e) => e.stopPropagation();
                }
            };

            links.forEach(interceptLink);

            return () => {
                links.forEach((link: HTMLAnchorElement) => {
                    link.onclick = null;
                });
            };
        }
    }

    return undefined;
};
