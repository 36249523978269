import { useEffect, useState } from 'react';
import { interceptCrossDomainLinkBehavior } from '@utils/intercept-cross-domain-link-behavior';

export interface DOMWatcherProps {
    selector: string;
    country: string;
    domain: string;
    pathname: string;
}

const useWatchElementInDOM = (props: DOMWatcherProps) => {
    const { selector, domain, pathname } = props;
    const [hasElement, setHasElement] = useState<boolean>(false);
    let firstLayerTargetElement: Element;

    useEffect(() => {
        const childListChangesCallback = (mutationList, observer): void => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    firstLayerTargetElement = document.querySelector(selector);

                    if (firstLayerTargetElement) {
                        setHasElement(true);
                        observer.disconnect();
                        break;
                    }
                }
            }
        };

        const observer: MutationObserver = new MutationObserver(
            childListChangesCallback
        );

        observer.observe(document.body, { childList: true });

        return () => observer.disconnect();
    }, [selector]);

    useEffect(() => {
        if (hasElement) {
            const subtreeChangesCallback = (mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (
                        mutation.type === 'childList' ||
                        mutation.type === 'subtree'
                    ) {
                        const secondLayerTargetElements =
                            document.querySelectorAll(`${selector} a`);

                        if (secondLayerTargetElements)
                            interceptCrossDomainLinkBehavior(domain, pathname);
                    }
                }
            };

            const observer = new MutationObserver(subtreeChangesCallback);

            observer.observe(document.querySelector(selector), {
                childList: true,
                subtree: true,
            });

            return () => {
                observer.disconnect();
            };
        }
    }, [hasElement, selector, domain, pathname]);

    return hasElement;
};

export default useWatchElementInDOM;
