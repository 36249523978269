import React, { useEffect, useState } from 'react';
import { useDeleteAccountContent } from '@sections/delete-account/hooks/use-delete-account-content';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import DeleteAccountComponent from '@sections/delete-account/delete-account';
import { useProfileData } from '@sections/account-portal/hooks/use-profile-data';
import SecondaryButton from '@common/secondary-button/secondary-button';
import { findPathByAlias } from '@routes/routesList';
import { useAccountSettingsContent } from '../account-settings-view/hooks/use-account-settings-content';
import './delete-account-view.scss';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { useConnectedStatusContext } from '@contexts/connectedStatusContext';

const DeleteAccountView = () => {
    const { setNotificationContext } = useNotificationContext();

    const deleteAccountContent = useDeleteAccountContent();
    const profile = useProfileData();
    const accountSettingsLabels = useAccountSettingsContent();
    const [hasContent, setHasContent] = useState(false);
    const accountSettingsView = findPathByAlias('AccountSettingsView');
    const accountSettingsPath =
        typeof accountSettingsView === 'object'
            ? accountSettingsView[1]
            : accountSettingsView;
    const { getConnectedStatus } = useConnectedStatusContext();

    const authenticationService = new AuthenticationService();
    authenticationService.updateState(findPathByAlias('DeleteAccountView'));

    useEffect(() => {
        if (deleteAccountContent && profile && accountSettingsLabels) {
            setHasContent(true);
        }
    }, [deleteAccountContent, profile, accountSettingsLabels]);

    useEffect(() => {
        if (profile?.vehicles) {
            getConnectedStatus(profile);
        }
    }, [profile]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    return (
        <div className="delete-account-view" data-testid="delete-account-view">
            <SecondaryButton
                className={
                    'return-to-account-button fmc-text-button fmc-text-button--chevron-left'
                }
                internal
                link={accountSettingsPath}
                dataTestId="return-to-account-button"
            >
                {deleteAccountContent?.returnButtonText}
            </SecondaryButton>

            {!hasContent && <ActivityIndicator className="full-height" />}

            {hasContent && (
                <DeleteAccountComponent
                    deleteAccountContent={deleteAccountContent}
                    memberId={profile.profile.memberId.toString()}
                    memberIdLabel={accountSettingsLabels.memberId}
                    accountSettingsPath={accountSettingsPath}
                />
            )}
        </div>
    );
};

export default DeleteAccountView;
