import React from 'react';
import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';

export interface OrderDetailsAddressProps {
    orderDetailsData: OmsOrderDetailsResponse;
    orderDetailsContent: OrderDetailsContent;
    className: string;
}
const OrderDetailsAddress = (props: OrderDetailsAddressProps) => {
    const { orderDetailsContent, orderDetailsData, className } = props;

    return (
        <div
            className={`${className}-address-container`}
            data-testid={`${className}-address-container`}
        >
            <div className={`${className}-billing-address-container`}>
                <p className={`${className}-billing-address-title`}>
                    {orderDetailsContent.billingAddress}
                </p>
                <p
                    className={`${className}-billing-address`}
                    data-testid={`${className}-billing-address-name`}
                >
                    {orderDetailsData.personInfoBillTo?.firstName}{' '}
                    {orderDetailsData.personInfoBillTo?.lastName}
                </p>
                <p
                    className={`${className}-billing-address`}
                    data-testid={`${className}-billing-address`}
                >
                    {orderDetailsData.personInfoBillTo?.addressLine1}{' '}
                    {orderDetailsData.personInfoBillTo?.addressLine2} {', '}
                    {orderDetailsData.personInfoBillTo?.city}
                    {', '}
                    {orderDetailsData.personInfoBillTo?.state}{' '}
                    {orderDetailsData.personInfoBillTo?.zipCode}
                </p>
            </div>
            {orderDetailsData.personInfoShipTo && (
                <div className={`${className}-shipping-address-container`}>
                    <p className={`${className}-shipping-address-title`}>
                        {orderDetailsContent.shippingAddress}
                    </p>
                    <p
                        className={`${className}-shipping-address`}
                        data-testid={`${className}-shipping-address-name`}
                    >
                        {orderDetailsData.personInfoShipTo?.firstName}{' '}
                        {orderDetailsData.personInfoShipTo?.lastName}
                    </p>
                    <p
                        className={`${className}-shipping-address`}
                        data-testid={`${className}-shipping-address`}
                    >
                        {orderDetailsData.personInfoShipTo?.addressLine1}{' '}
                        {orderDetailsData.personInfoShipTo?.addressLine2} {', '}
                        {orderDetailsData.personInfoShipTo?.city}
                        {', '}
                        {orderDetailsData.personInfoShipTo?.state}{' '}
                        {orderDetailsData.personInfoShipTo?.zipCode}{' '}
                    </p>
                </div>
            )}
        </div>
    );
};

export default OrderDetailsAddress;
