import React, { ReactElement, useEffect, useState } from 'react';
import './notification-banner.scss';
import { useNotificationBannerContent } from './hooks/use-notification-banner-content';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { useDealerConsentContent } from '@sections/preferred-dealer-consent/hooks/use-dealer-consent';

export const NotificationBanner = ({ type }) => {
    const {
        notificationType,
        setNotificationContext,
        isFromDashboard,
        notificationMessage,
    } = useNotificationContext();
    const notificationBannerContent = useNotificationBannerContent();
    const dealerConsentContent = useDealerConsentContent();
    const [visible, setVisible] = useState(false);
    const [nested, setNested] = useState(false);
    const validNotificationTypes: string[] = [
        'success',
        'error',
        'default',
        'dealer-consent-success',
        'info',
    ];
    const scrollUtil = new ScrollUtil();
    const [isSuccess] = useState(
        type === NotificationType.Success ? 'success' : null
    );
    const [isError] = useState(
        type === NotificationType.Error ? 'error' : null
    );
    const isDealerConsentSuccess =
        notificationType === NotificationType.DealerConsentSuccess;
    const successText = notificationBannerContent?.notificationSuccessText;
    const errorText = notificationBannerContent?.notificationFailureText;

    const renderBannerText = (type: string) => {
        if (notificationMessage) return notificationMessage;

        switch (type) {
            case isSuccess:
            case 'success':
                return successText;
            case isError:
            case 'error':
                return errorText;
        }
    };

    const generateLincolnDealerConsentClass = (): string => {
        return isDealerConsentSuccess ? 'dealer-consent-success' : '';
    };

    const renderDealerConsentSuccess = (): ReactElement => {
        return (
            <div className="dealer-consent-success__container">
                <p className="title">
                    {dealerConsentContent?.notificationBannerHeaderText}
                </p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: dealerConsentContent?.notificationBannerBodyText,
                    }}
                />
            </div>
        );
    };

    const cancelAnimation = () => {
        setNested(false);
        setVisible(false);
        // Reset notification banner to default of 'none'
        setNotificationContext(NotificationType.None, false);
    };

    useEffect(() => {
        if (validNotificationTypes.includes(notificationType)) {
            setVisible(true);
            scrollUtil.scrollPageToTop();
            setTimeout(() => {
                setNested(true);
            }, 500);
        }
    }, [notificationType]);

    useEffect(() => {
        if (notificationType === '' && !isFromDashboard) {
            cancelAnimation();
        }
    }, [isFromDashboard, notificationType]);

    useEffect(() => {
        if (visible && !isFromDashboard) {
            // Close banner after 10 seconds
            setTimeout(() => {
                cancelAnimation();
            }, 10000);
        }
    }, [visible]);

    return (
        <>
            <div
                className={`notification-banner-container ${generateLincolnDealerConsentClass()}`}
                role="alert"
            >
                <div
                    className={`${
                        visible
                            ? `notification-text-wrapper-parent active ${notificationType}`
                            : 'notification-text-wrapper-parent closed'
                    }`}
                />
                <div
                    className={`${
                        nested && notificationType
                            ? `fmc-top-banner active fmc-top-banner--persistent fmc-top-banner--${notificationType}`
                            : 'notification-wrapper closed'
                    }`}
                >
                    {nested && (
                        <div className="fmc-top-banner__inner">
                            <div className="fmc-top-banner__content">
                                {isDealerConsentSuccess &&
                                    renderDealerConsentSuccess()}

                                {isFromDashboard && (
                                    <>
                                        <a
                                            href={
                                                notificationBannerContent?.specialAlertCtaHref
                                            }
                                            aria-label={
                                                notificationBannerContent?.specialAlertCtaAriaLabel
                                            }
                                            target="_self"
                                            data-testid="special-alert-cta"
                                        >
                                            {
                                                notificationBannerContent?.specialAlertCtaLabelText
                                            }
                                        </a>
                                    </>
                                )}

                                {!isFromDashboard &&
                                    !isDealerConsentSuccess &&
                                    renderBannerText(notificationType)}
                            </div>
                            <button
                                onClick={cancelAnimation}
                                aria-label="close"
                                className="fmc-top-banner__dismiss"
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
