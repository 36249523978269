import React, { ReactElement } from 'react';
import { InputField } from '@common/form-fields/form-fields';
import { formatPhoneNumberByCountry } from '@utils/values-by-country/value-by-country';
import { AccountSettingsFormsLabels } from '../hooks/use-personal-information-content/use-personal-information-content';
import { UserPersonalData } from '../personal-information';

interface InputValidation {
    [key: string]: {
        validationRules: any;
        validationPattern: string;
        label: string;
    };
}

interface Error {
    [key: string]: {
        status: boolean;
        message: string;
    };
}

export interface Props {
    userData: UserPersonalData;
    focus: any;
    setFocusToField: (field: string, status: boolean) => void;
    accountSettingsFormsLabels: AccountSettingsFormsLabels;
    inputValidations: InputValidation;
    error: Error;
    handleChange: (e: KeyboardEvent) => void;
    countryCode: string;
}

const PhoneNumber = (props: Props): ReactElement => {
    const {
        userData,
        focus,
        setFocusToField,
        accountSettingsFormsLabels,
        inputValidations,
        error,
        handleChange,
        countryCode,
    } = props;
    const mobilePhoneField = accountSettingsFormsLabels?.mobilePhoneField;
    const primaryPhoneField = accountSettingsFormsLabels?.primaryPhoneField;

    return (
        <div
            data-testid="phoneNumber-container"
            className="input-section-container phone-number"
        >
            <div className={`phone-number-field-group`}>
                {/* Primary Phone Number - hidden in regions without an authored value */}
                {primaryPhoneField ? (
                    <InputField
                        id="phoneNumber"
                        dataTestId="phoneNumber"
                        label={primaryPhoneField}
                        showLabel={focus.phone}
                        handleChange={handleChange}
                        name="phoneNumber"
                        value={formatPhoneNumberByCountry(
                            countryCode,
                            userData.phoneNumber
                        )}
                        className="two-fields"
                        validationRules={
                            inputValidations.phoneNumber.validationRules
                        }
                        validationPattern={
                            inputValidations.phoneNumber.validationPattern
                        }
                        error={error.phoneNumber}
                        onFocus={() => setFocusToField('phone', true)}
                        onBlur={() =>
                            userData.phoneNumber.length === 0 &&
                            setFocusToField('phone', false)
                        }
                        placeholder={primaryPhoneField}
                    />
                ) : null}
            </div>

            {/* Mobile Phone Number */}
            <div className="phone-number-field-group">
                {mobilePhoneField && (
                    <InputField
                        id="mobilePhoneNumber"
                        label={mobilePhoneField}
                        showLabel={focus.mobile}
                        handleChange={handleChange}
                        name="mobilePhoneNumber"
                        value={formatPhoneNumberByCountry(
                            countryCode,
                            userData.mobilePhoneNumber
                        )}
                        className="two-fields"
                        dataTestId="mobilePhoneNumber"
                        validationRules={
                            inputValidations.mobilePhoneNumber.validationRules
                        }
                        validationPattern={
                            inputValidations.mobilePhoneNumber.validationPattern
                        }
                        error={error.mobilePhoneNumber}
                        onFocus={() => setFocusToField('mobile', true)}
                        onBlur={() =>
                            userData.mobilePhoneNumber.length === 0 &&
                            setFocusToField('mobile', false)
                        }
                        placeholder={
                            accountSettingsFormsLabels.mobilePhoneField
                        }
                    />
                )}
                {/* Confirm Mobile Phone Number - only visible when there is an authored value */}
                {accountSettingsFormsLabels?.confirmMobilePhoneField ? (
                    <InputField
                        id="confirmMobilePhoneNumber"
                        label={
                            accountSettingsFormsLabels.confirmMobilePhoneField
                        }
                        showLabel={focus.confirmMobile}
                        handleChange={handleChange}
                        name="confirmMobilePhoneNumber"
                        value={formatPhoneNumberByCountry(
                            countryCode,
                            userData.confirmMobilePhoneNumber
                        )}
                        className="two-fields"
                        dataTestId="confirmMobilePhoneNumber"
                        error={error.confirmMobilePhoneNumber}
                        onFocus={() => setFocusToField('confirmMobile', true)}
                        onBlur={() =>
                            userData.confirmMobilePhoneNumber.length === 0 &&
                            setFocusToField('confirmMobile', false)
                        }
                        placeholder={
                            accountSettingsFormsLabels.confirmMobilePhoneField
                        }
                    />
                ) : null}
            </div>
        </div>
    );
};

export default PhoneNumber;
