import React, { useEffect, useState } from 'react';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import { OrderLineDetails } from '@services/oms-service/oms-service';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import OrderDetailsItem from '@views/order-details-view/components/order-details-item/order-details-item';
import BingService from '@services/bing-service/bing-service';
import HttpService from '@services/http-service/http-service';
import { INSTALL_LINES_ID } from '@/constants';
import {
    NVC_ORDER_STATUS_MAP,
    transformDeliveryMethod,
} from '../../nvc-status-map';

export interface SubOrderProps {
    orderStatusContent: OrderStatusContent;
    orderDetailsContent: OrderDetailsContent;
    orderDetailsItems: OrderLineDetails[];
}

interface CombinedOrder {
    status: string;
    items: OrderLineDetails[];
}

const SubOrder = (props: SubOrderProps) => {
    const { orderStatusContent, orderDetailsContent, orderDetailsItems } =
        props;
    const httpService = HttpService;
    const bingService = new BingService(httpService);
    const isShipping = orderDetailsItems[0].deliveryMethod === 'SHP';
    const isPickup = orderDetailsItems[0].deliveryMethod === 'PICK';
    const hasItems = Boolean(orderStatusContent && orderDetailsItems?.length);
    const [dealerToPickUpFrom, setDealerToPickUpFrom] = useState<string>(null);
    const [combinedOrders, setCombinedOrders] = useState<CombinedOrder[]>([]);

    const determineDisplayStatus = (
        rawStatus: string,
        deliveryMethod: string
    ): string => {
        const deliveryMethodTransformed: string =
            transformDeliveryMethod(deliveryMethod);

        const statusOption = NVC_ORDER_STATUS_MAP[
            deliveryMethodTransformed
        ]?.find((option) => option.options.includes(rawStatus.toLowerCase()));

        return statusOption
            ? orderStatusContent[statusOption.name] || rawStatus
            : rawStatus;
    };

    const combineItemsBasedOnStatus = () => {
        const newOrderItems = orderDetailsItems
            .filter(
                (orderLineItem) =>
                    orderLineItem.item.itemId !== INSTALL_LINES_ID
            )
            .map((orderLineItem) => {
                const displayStatus = determineDisplayStatus(
                    orderLineItem.status,
                    orderLineItem.deliveryMethod
                );
                return {
                    ...orderLineItem,
                    status: displayStatus,
                };
            });

        const uniqueStatus = [
            ...new Set(newOrderItems.map((item) => item.status)),
        ];

        const combinedOrders = uniqueStatus.map((status) => ({
            status,
            items: newOrderItems.filter((item) => item.status === status),
        }));

        setCombinedOrders(combinedOrders);
    };

    const getDealer = async () => {
        const response = await bingService.getDealerInfoById(
            orderDetailsItems[0].shipNode
        );

        if (response?.length) {
            setDealerToPickUpFrom(response[0].dealerName);
        }
    };

    useEffect(() => {
        if (isPickup) {
            (async () => await getDealer())();
        }
        combineItemsBasedOnStatus();
    }, []);

    return (
        <div
            className={`sub-order__container ${isShipping ? 'shipping' : ''}`}
            data-testid="sub-order-container"
        >
            {isPickup && dealerToPickUpFrom && (
                <p
                    className="pick-up-at-dealer"
                    data-testid="pick-up-at-dealer"
                >
                    {orderDetailsContent.dealerPickUpAtText}{' '}
                    <span>{dealerToPickUpFrom}</span>
                </p>
            )}
            {hasItems &&
                combinedOrders.map((orderLine) =>
                    orderLine.items.map((item, index) => {
                        let orderLineInstallation = [];
                        if (
                            item.serviceAssociations &&
                            item.serviceAssociations?.serviceAssociation != null
                        ) {
                            orderLineInstallation = orderDetailsItems.filter(
                                (e) =>
                                    e.orderLineKey ==
                                    item.serviceAssociations
                                        ?.serviceAssociation[0].serviceLine
                                        .orderLineKey
                            );
                        }

                        return (
                            <>
                                <OrderDetailsItem
                                    key={item.orderLineKey}
                                    itemData={item}
                                    orderDetailsContent={orderDetailsContent}
                                    orderStatusContent={orderStatusContent}
                                    combinedStatus={index == 0 ? true : false}
                                />
                                {orderLineInstallation.length > 0 && (
                                    <OrderDetailsItem
                                        key={
                                            orderLineInstallation[0]
                                                .orderLineKey
                                        }
                                        itemData={orderLineInstallation[0]}
                                        orderDetailsContent={
                                            orderDetailsContent
                                        }
                                        orderStatusContent={orderStatusContent}
                                        combinedStatus={false}
                                    />
                                )}
                            </>
                        );
                    })
                )}
        </div>
    );
};

export default SubOrder;
