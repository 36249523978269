import React from 'react';

interface RightCaretProps {
    color: string;
}

const RightCaret = (props: RightCaretProps) => {
    return (
        <div className="secondary-nav__right-caret" data-testid="right-caret">
            <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.8 8.00005L1.6 0.800049L0.76 1.64005L7.12 8.00005L0.76 14.3601L1.6 15.2001L8.8 8.00005Z"
                    fill={props.color}
                />
            </svg>
        </div>
    );
};

export default RightCaret;
