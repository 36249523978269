import React, { useContext, useEffect, useState } from 'react';
import { scriptService } from '@services/script-service/script-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import AuthenticationService from '@services/authentication-service/authentication-service';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import seoService from '@services/seo-service/seo-service';
import { useAnalytics } from '@hooks/use-analytics';
import { findPathByAlias } from '@routes/routesList';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import './wallet-view.scss';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import ReturnButton from '@sections/return-button/return-button';
import { IMG_COUNTRIES } from '@constants';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import ProfileService from '@/services/profile-service/profile-service';
import { useConnectedStatusContext } from '@contexts/connectedStatusContext';
import { useLocation } from 'react-router-dom';
import useWatchElementInDOM from '@hooks/use-dom-watcher';

export const WalletView = () => {
    useAnalytics('ownerAccountWallet');
    const { setNotificationContext } = useNotificationContext();
    const authenticationService = new AuthenticationService();
    const profileService = new ProfileService();
    const appConfiguration = new AppConfigurationService();
    const domain = appConfiguration.currentDomain;
    const location = useLocation();
    const scrollUtil = new ScrollUtil();
    const returnButtonContent = useReturnButtonContent();
    const [scriptLoaded, setLoaded] = useState(false);
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    const [swapToken, setSwapToken] = useState<string>('');
    const [fullProfileResponse, setFullProfileResponse] =
        useState<ProfileWithVehiclesResponse>(null);
    const { getConnectedStatus } = useConnectedStatusContext();

    seoService.set(
        {
            pageTitle: appInformationContent?.walletPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription: appInformationContent?.walletPageDescription,
        },
        serverContext
    );

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    scriptService.loadStripeScript();
                    scriptService.loadWalletScript(() => {
                        setLoaded(true);
                    });
                    authenticationService
                        .getSwapToken(
                            IMG_COUNTRIES.includes(
                                appConfiguration.currentCountryCode
                            )
                                ? 'Fordpay-pps'
                                : 'GlobalUX-EU'
                        )
                        .then((token) => {
                            console.log(token);
                            setSwapToken(token.access_token);
                        });
                    profileService.requestLite(true).then((response) => {
                        if (response) {
                            setFullProfileResponse(response);
                        }
                    });
                } else {
                    authenticationService.updateState(
                        findPathByAlias('WalletView')
                    );
                    authenticationService.login();
                }
            });
        scrollUtil.scrollPageToTop();
    }, []);

    useEffect(() => {
        if (fullProfileResponse) {
            getConnectedStatus(fullProfileResponse);
        }
    }, [fullProfileResponse]);

    useWatchElementInDOM({
        selector: '.ReactModalPortal',
        domain,
        pathname: location.pathname,
        country: appConfiguration.currentCountryCode,
    });

    return (
        returnButtonContent && (
            <div className="wallet-view-container" data-testid="wallet-view">
                <ReturnButton returnButtonContent={returnButtonContent} />
                {scriptLoaded && swapToken && serverContext.adToken ? (
                    <div data-testid="ford-pay-wallet-transaction-history">
                        <fordpay-wallet-transaction-history
                            azure-ad-token={serverContext.adToken}
                            customer-auth-token={swapToken}
                            customer-auth-token-type="CAT"
                            merchant-currency="USD"
                            merchant-country={appConfiguration.currentRegionCode.toUpperCase()}
                            language={
                                appConfiguration.currentLanguageRegionCapitalizedCode
                            }
                        ></fordpay-wallet-transaction-history>
                    </div>
                ) : (
                    <ActivityIndicator className="full-height" />
                )}
            </div>
        )
    );
};
