import { useExperienceContent } from '@hooks/use-server-content';
import { useEffect, useState } from 'react';
import { SecondaryNavContent } from '@sections/secondary-nav/secondary-nav';

const useSecondaryNavContent = () => {
    const [content, setContent] = useState<SecondaryNavContent>(null);
    const [secondaryNavContent] = useExperienceContent<SecondaryNavContent>(
        'secondarynav',
        'secondarynav',
        'container',
        null,
        false,
        'global-owner'
    );

    useEffect(() => {
        if (secondaryNavContent) {
            setContent(secondaryNavContent[':items']?.secondary_nav);
        }
    }, [secondaryNavContent]);

    return content;
};

export default useSecondaryNavContent;
